// RecommendationNotes.jsx
import React from 'react';
import './RecommendationNotes.css';
import ZeroStateComponent from '../ZeroStateComponent/ZeroStateComponent';

const RecommendationNotes = ({ recommendation_notes }) => {
  // alert(recommendation_notes)
  if (recommendation_notes.length === 0) {
    return (
      <ZeroStateComponent
        tableTitle="Recommendation Notes"
        tableContent="Recommendation Notes not found. Trigger analysis from the Data Section"
      />
      
    );
  }
  return (
    
    <div className="recommendation-notes">
      {/* <h2>Recommendation Notes</h2> */}
      {<h2>Key Observations</h2>}
      <div className="recommendation-notes-container">
        <div>
          <ul>
            {Array.isArray(recommendation_notes)
              ? recommendation_notes.map((note, index) => (
                  <React.Fragment key={index}>
                    {index === 0}
                    <li>{note}</li>
                  </React.Fragment>
                ))
              : recommendation_notes.approval_reasoning.map((reason, index) => (
                  <React.Fragment key={index}>
                    {index === 0 && <strong>Highlights:</strong>}
                    <li>{reason}</li>
                  </React.Fragment>
                ))}
          </ul>
        </div>
        <div>
          <ul>
            {Array.isArray(recommendation_notes)
              ? recommendation_notes.map((note, index) => (
                  <React.Fragment key={index}></React.Fragment>
                ))
              : recommendation_notes.repayment_ability.map((issue, index) => (
                  <React.Fragment key={index}>
                    {index === 0 && <strong>Repayment Ability:</strong>}
                    <li>{issue}</li>
                  </React.Fragment>
                ))}
          </ul>
        </div>
        <div>
          <ul>
            {Array.isArray(recommendation_notes)
              ? recommendation_notes.map((note, index) => (
                  <React.Fragment key={index}></React.Fragment>
                ))
              : recommendation_notes.repayment_intent.map((issue, index) => (
                  <React.Fragment key={index}>
                    {index === 0 && <strong>Repayment Intent:</strong>}
                    <li>{issue}</li>
                  </React.Fragment>
                ))}
          </ul>
        </div>
        <div>
          <ul>
            {Array.isArray(recommendation_notes)
              ? recommendation_notes.map((note, index) => (
                  <React.Fragment key={index}></React.Fragment>
                ))
              : recommendation_notes.repayment_compliance.map((issue, index) => (
                  <React.Fragment key={index}>
                    {index === 0 && <strong>Data Availability:</strong>}
                    <li>{issue}</li>
                  </React.Fragment>
                ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RecommendationNotes;
