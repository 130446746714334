import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import logo from '../../brand.png';
import './Layout.css';

import LoginModal from '../LoginModal/LoginModal';

const Layout = ({ isAuthenticated, setIsAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    setIsAuthenticated(false);
    navigate("/login");
  };

  // const handleCloseModal = () => {
  //   navigate("/");
  // };

  return (
    <>
      <div className="top-bar">
      <div className='layout-sidebar-header'>
        {/* <img src={logo} alt="Kreditmind Logo" className="logo" width="200px" height="35px" onClick={() => navigate('/')} /> */}
        </div>
        <div className="auth-section">
          {isAuthenticated ? (
            <button onClick={handleLogout} className="logout-button">Logout</button>
          ) : (
            <button onClick={() => navigate('/login')} className="login-button">Login</button>
          )}
        </div>
      </div>
      
      <main>
        <Outlet />
        {!isAuthenticated && <LoginModal setIsAuthenticated={setIsAuthenticated} />}
        {/* {!isAuthenticated && <LoginModal setIsAuthenticated={setIsAuthenticated} onClose={handleCloseModal} />} */}
      </main>
    </>
  );
};

export default Layout;
