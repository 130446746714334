import React, { useState } from 'react';
import './VerticalTabs.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const VerticalTabs = ({ items, onItemSelect }) => {
  const [openItems, setOpenItems] = useState({});
  const [activeItem, setActiveItem] = useState(null);

  const toggleItem = (item) => {
    setOpenItems(prev => ({
      ...prev,
      [item.id]: !prev[item.id]
    }));
  };

  const handleItemClick = (item) => {
    if (item.enabled) {
      setActiveItem(item.id);
      onItemSelect(item.id); // Passing only the ID of the selected item
    }
  };

  return (
    <div className="vertical-tabs">
      {items.map(item => (
        <div key={item.id} className="accordion-section">
          <button
            className={`accordion-title ${activeItem === item.id ? 'active' : ''} ${!item.enabled ? 'disabled' : ''}`}
            onClick={() => item.children ? toggleItem(item) : handleItemClick(item)}
            disabled={!item.enabled} // Disable the button if the item is not enabled
          >
            <div className='accordion-item'>
              <span>{item.icon}</span>
            </div>
            <div className='accordion-item'>
              <span>{item.title}</span>
            </div>
            {item.children && (
              <span className={`accordion-icon ${openItems[item.id] ? 'expanded' : ''}`}>
                {openItems[item.id] ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            )}
          </button>
          {item.children && openItems[item.id] && (
            <div className="accordion-children expanded">
              {item.children.map(child => (
                <button
                  key={child.id}
                  className={`accordion-title ${activeItem === child.id ? 'active' : ''}`}
                  onClick={() => handleItemClick(child)}
                >
                  <div className='accordion-item'>
                    <span>{child.icon}</span>
                  </div>
                  <div className='accordion-item'>
                    <span>{child.title}</span>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default VerticalTabs;
