import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FaClipboardList, FaMoneyBillWave, FaBalanceScale, FaFileAlt, FaExclamationCircle, FaCalendarAlt, FaFolderOpen } from 'react-icons/fa';
import DocumentUploader from '../documents/DocumentUploader';
import AnalysisSummary from '../insights/AnalysisSummary';
import BankingCashflowInsights from '../insights/banking/BankingCashflowInsights';
import { getCustomerDetails, getAnalysis, getInsight } from '../../api/apiServices';
import GSTInsights from '../insights/gst/GSTInsights';
import BureauDataInsights from '../insights/BureauDataInsights';
import BankingABBInsights from '../insights/banking/BankingABBInsights';
import BankingFraudAnalysis from '../insights/banking/BankingFraudAnalysis';
import BankingForecastInsights from '../insights/banking/BankingForecastInsights';
import BankingTransactions from '../insights/banking/BankingTransactions';
import BankingRepayments from '../insights/banking/BankingRepayments';
import VerticalTabs from '../VerticalTabs/VerticalTabs';
import OnDemandAnalysis from '../OnDemandAnalysis/OnDemandAnalysis';
import Loader from '../Loader/Loader';
import logo from '../../brand.png';
import { decodeJWT } from '../../utils/jwtUtils';
import { getEnabledAccordionItems } from '../../utils/accordionUtils';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const { customer_uuid } = useParams();
  const location = useLocation();
  const passedCustomerData = location.state?.customer;
  const [activeTab, setActiveTab] = useState(null);
  const [isMinimized, setIsMinimized] = useState(true);
  const [selectedAccordionItem, setSelectedAccordionItem] = useState(null);
  const [insightData, setInsightData] = useState(null);
  const [analysisData, setAnalysisData] = useState(passedCustomerData?.latest_analysis || null);
  const [customerData, setCustomerData] = useState(passedCustomerData || null);
  const [loading, setLoading] = useState(true);
  const isFetching = useRef(false);
  const decodedPayload = decodeJWT();

  const fetchData = async () => {
    if (isFetching.current) return;
    isFetching.current = true;

    try {
      if (!passedCustomerData) {
        const customerInfo = await getCustomerDetails(customer_uuid);
        setCustomerData(customerInfo.data);
      }

      let analysisResponse;
      if (!analysisData) {
        analysisResponse = await getAnalysis(customer_uuid);
        setAnalysisData(analysisResponse?.data);
      }

      const insightDataResponse = await getInsight(customer_uuid);
      setInsightData(insightDataResponse);

      if (!analysisData) {
        setActiveTab('10');
        setSelectedAccordionItem({
          id: '10',
          key: 'data',
          title: 'Documents',
          icon: <FaFileAlt />,
          content: <DocumentUploader customerUuid={customer_uuid} fetchData={fetchData} onSelectTab={handleTabSelect} onDataReady={handleDataReady} />,
        });
        setLoading(false);
        return;
      }

      if (analysisData?.status === 'completed' && insightDataResponse) {
        setActiveTab('2');
        setSelectedAccordionItem({
          id: '2',
          key: 'analysisSummary',
          title: 'Analysis Summary',
          icon: <FaClipboardList />,
          content: <AnalysisSummary customer_uuid={customer_uuid} insights_data={insightDataResponse} analysis_data={analysisData} />,
        });
      } else {
        setActiveTab('10');
        setSelectedAccordionItem({
          id: '10',
          key: 'data',
          title: 'Documents',
          icon: <FaFileAlt />,
          content: <DocumentUploader customerUuid={customer_uuid} fetchData={fetchData} onSelectTab={handleTabSelect} onDataReady={handleDataReady} />,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error(`Error fetching data for customer ${customer_uuid}:`, error);
      setLoading(false);
    } finally {
      isFetching.current = false;
    }
  };

  useEffect(() => {
    fetchData();
  }, [customer_uuid]);

  useEffect(() => {
    if (customerData) {
      setLoading(false);
    }
  }, [customerData]);

  const handleTabSelect = (tabId) => {
    const tab = accordionData.find((item) => item.id === tabId);
    if (tab) {
      setActiveTab(tabId);
      setSelectedAccordionItem(tab);
    }
  };

  const handleDataReady = (customerUuid, analysisData, insightData) => {
    setCustomerData((prev) => ({
      ...prev,
      customer_uuid: customerUuid,
      latest_analysis: analysisData,
    }));
    setAnalysisData(analysisData);
    setInsightData(insightData);
    setLoading(false);
  };

  const accordionData = [
    {
      id: '2',
      key: 'analysisSummary',
      title: 'Analysis Summary',
      icon: <FaClipboardList />,
      content: <AnalysisSummary customer_uuid={customer_uuid} insights_data={insightData} analysis_data={analysisData} />,
    },
    {
      id: '4',
      key: 'cashflowAnalysis',
      title: 'Cashflow Analysis',
      icon: <FaBalanceScale />,
      content: <BankingCashflowInsights customer_uuid={customer_uuid} insights_data={insightData} />,
    },
    {
      id: '5',
      key: 'allTransactions',
      title: 'All Transactions',
      icon: <FaBalanceScale />,
      content: <BankingTransactions customer_uuid={customer_uuid} />,
    },
    {
      id: '6',
      key: 'abb',
      title: 'ABB',
      icon: <FaBalanceScale />,
      content: <BankingABBInsights customer_uuid={customer_uuid} insights_data={insightData} />,
    },
    {
      id: '11',
      key: 'loanSchedule',
      title: 'Loan Schedule',
      icon: <FaBalanceScale />,
      content: <BankingRepayments customer_uuid={customer_uuid} insights_data={insightData} />,
    },
    {
      id: '3',
      key: 'gstAnalysis',
      title: 'GST Analysis',
      icon: <FaMoneyBillWave />,
      content: <GSTInsights customer_uuid={customer_uuid} insights_data={insightData} />,
    },
    {
      id: '7',
      key: 'bureau',
      title: 'Bureau',
      icon: <FaFileAlt />,
      content: <BureauDataInsights customer_uuid={customer_uuid} insights_data={insightData} />,
    },
    {
      id: '8',
      key: 'fraudAnalysis',
      title: 'Fraud Analysis',
      icon: <FaExclamationCircle />,
      content: <BankingFraudAnalysis customer_uuid={customer_uuid} insights_data={insightData} />,
    },
    {
      id: '9',
      key: 'transactionForecast',
      title: 'Transaction Forecast',
      icon: <FaCalendarAlt />,
      content: <BankingForecastInsights customer_uuid={customer_uuid} insights_data={insightData} />,
    },
    {
      id: '10',
      key: 'data',
      title: 'Data',
      icon: <FaFolderOpen />,
      content: <DocumentUploader
                customerUuid={customer_uuid}
                fetchData={fetchData}
                onSelectTab={handleTabSelect}
                onDataReady={handleDataReady}  // <-- Pass the callback here
              />,
    },
  ];

  const enabledAccordionItems = getEnabledAccordionItems(accordionData, decodedPayload);

  return (
    <div className="dashboard">
      <div className="sidebar">
        <div className="sidebar-header">
          <div className="sidebar-logo-container">
            <img src={logo} alt="Kreditmind Logo" className="sidebar-logo" onClick={() => navigate('/')} />
          </div>
          <div className="sidebar-customer-name">{customerData?.name}</div>
        </div>
        <VerticalTabs items={enabledAccordionItems} onItemSelect={handleTabSelect} activeTab={activeTab} />
      </div>
      <div className="dashboard-main">
        <div className="content">
          {loading ? <Loader /> : selectedAccordionItem && selectedAccordionItem.content}
        </div>
        <OnDemandAnalysis isMinimized={isMinimized} toggleMinimize={() => setIsMinimized(!isMinimized)} customer_uuid={customer_uuid} />
      </div>
    </div>
  );
};

export default Dashboard;
