import React, { useState } from 'react';
import './CustomerModal.css';
import { createCustomer } from '../../api/apiServices';
import CustomDropdown from '../CustomDropdown/CustomDropdown';

const CustomerModal = ({ isOpen, onCloseModal, onCustomerCreated }) => {
  const [customerData, setCustomerData] = useState({
    name: '',
    type: '',
    identification_number: '',
  });
  const [identificationType, setIdentificationType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});

  const validateIdentificationNumber = (number) => {
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const cinPattern = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
    const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    const llpinPattern = /^[A-Z]{3}-[0-9]{4}$/;

    if (panPattern.test(number)) return 'PAN';
    if (cinPattern.test(number)) return 'CIN';
    if (gstPattern.test(number)) return 'GST';
    if (llpinPattern.test(number)) return 'LLPIN';
    return '';
  };

  const isFormValid =
    customerData.name &&
    customerData.type &&
    validateIdentificationNumber(customerData.identification_number);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
    if (name === 'identification_number') {
      setIdentificationType(validateIdentificationNumber(value));
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouchedFields({ ...touchedFields, [name]: true });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);
    setIsLoading(true);

    const payload = {
      name: customerData.name,
      identification_type: identificationType.toLowerCase(),
      type: customerData.type,
      identification_number: customerData.identification_number,
    };

    try {
      await createCustomer(payload);
      onCloseModal(); // Close the modal on success
      onCustomerCreated('Customer created successfully!', true);
    } catch (error) {
      onCustomerCreated('Failed to create customer. Please try again.', false);
    }

    setIsLoading(false); // Set isLoading back to false after submission is complete
  };

  const industryTypeOptions = [
    { label: 'Apparel and Accessories', value: 'apparel_and_accessories' },
    { label: 'Consumer Electronics', value: 'consumer_electronics' },
    { label: 'Food Services', value: 'food_services' },
    { label: 'SaaS', value: 'saas' },
    { label: 'Ed-Tech', value: 'edtech' },
    { label: 'Agri', value: 'agri' },
    { label: 'Others', value: 'others' },
  ];

  return (
    <div className="modal-backdrop">
      <div className="customer-modal">
        <form onSubmit={handleSubmit}>
          <label>Borrower Name <span className="required">*</span></label>
          <input 
            name="name" 
            required 
            onChange={handleChange} 
            onBlur={handleBlur}
            placeholder="Enter Borrower Name" 
            className={!customerData.name && (isFormSubmitted || touchedFields.name) ? 'input-error' : ''}
          />

          <CustomDropdown
            title="Borrower Industry Type"
            value={customerData.type}
            onChange={(value) => setCustomerData({ ...customerData, type: value })}
            placeholder="Select Bank"
            options={industryTypeOptions}
          />

          <label>Borrower Identification Id <span className="required">*</span></label>
          <div className="identification-input-container">
            <input
              name="identification_number"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="PAN, GST, CIN, or LLPIN"
              className={!validateIdentificationNumber(customerData.identification_number) && (isFormSubmitted || touchedFields.identification_number) ? 'input-error' : ''}
            />
            <span className="identification-type">{identificationType}</span>
          </div>

          <div className="customer-modal-button-container">
            <button type="submit" disabled={isLoading || !isFormValid}>
              {isLoading ? 'Creating...' : 'Submit'}
            </button>
            <button type="button" onClick={onCloseModal}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerModal;
