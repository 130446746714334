import React, { useState, useEffect } from 'react';
import SkeletonLoader from '../../loaders/skeltons/SkeletonLoader';
import { getAnalysis } from '../../../api/apiServices';
import './BankingCashflowInsights.css';
import CreditsBreakdown from './DynamicTable';
import BankingTransactions from './BankingTransactions';
import Modal from 'react-modal';
import Loader from '../../Loader/Loader';

const BankingCashflowInsights = ({ customer_uuid, insights_data }) => {
  const [bankingData, setBankingData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [selectedPartyCategory, setSelectedPartyCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    total_revenue_sources: { final_party_name: [], final_party_category: [] },
    total_non_revenue_items: { final_party_name: [], final_party_category: [] },
    total_expenses: { final_party_name: [], final_party_category: [] },
    total_non_expenses: { final_party_name: [], final_party_category: [] },
  });

  useEffect(() => {
    const extractData = () => {
      try {
        setIsLoading(true);

        if (insights_data && insights_data.data) {
          console.log('Insights data loaded:', insights_data.data);
          const total_revenue_sources = insights_data.data.total_revenue_sources || [];
          const total_expenses = insights_data.data.total_expenses || [];
          const total_non_revenue_items = insights_data.data.total_non_revenue_items || [];
          const total_non_expenses = insights_data.data.total_non_expenses || [];

          setBankingData({
            total_expenses,
            total_non_expenses,
            total_revenue_sources,
            total_non_revenue_items,
          });

          setFilteredData({
            total_expenses,
            total_non_expenses,
            total_revenue_sources,
            total_non_revenue_items,

          });

          setIsLoading(false);
        } else {
          console.error('insights_data or insights_data.data is not defined');
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching banking data:', error);
        setIsLoading(false);
      }
    };

    extractData();
  }, [insights_data]);

  useEffect(() => {
    if (bankingData) {
      console.log('Applying filters to banking data:', bankingData);
      const newFilteredData = {
        total_expenses: applyFilters(bankingData.total_expenses, filters.total_expenses),
        total_non_expenses: applyFilters(bankingData.total_non_expenses, filters.total_non_expenses),
        total_revenue_sources: applyFilters(bankingData.total_revenue_sources, filters.total_revenue_sources),
        total_non_revenue_items: applyFilters(bankingData.total_non_revenue_items, filters.total_non_revenue_items),

      };

      console.log('Filtered data:', newFilteredData);
      setFilteredData(newFilteredData);
    }
  }, [filters, bankingData]);

  const applyFilters = (data, filter) => {
    let filteredData = data;
    if (filter.final_party_name.length > 0) {
      filteredData = filteredData.filter((item) => filter.final_party_name.includes(item.final_party_name));
    }
    if (filter.final_party_category.length > 0) {
      filteredData = filteredData.filter((item) => filter.final_party_category.includes(item.final_party_category));
    }
    return filteredData;
  };

  const handleRowClick = (partyCategory) => {
    setSelectedPartyCategory(partyCategory);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (selectedPartyCategory) {
      // Reset filters when opening the modal
      setFilters({
        total_revenue_sources: { final_party_name: [], final_party_category: [] },
        total_non_revenue_items: { final_party_name: [], final_party_category: [] },
        total_expenses: { final_party_name: [], final_party_category: [] },
        total_non_expenses: { final_party_name: [], final_party_category: [] },
      });
    }
  }, [selectedPartyCategory]);

  const fixedColumns = [
    {
      key: 'final_party_category',
      header: 'Party Category',
      render: (value) => (
        <span className="clickable-text" onClick={() => handleRowClick(value)}>
          {value}
        </span>
      ),
    },
    {
      key: 'final_party_name',
      header: 'Party Name',
    },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPartyCategory(null);
  };

  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          {/* <SkeletonLoader /> */}
          <Loader />
        </div>
      ) : (
        <>
          {filteredData && (
            <>
              {Object.entries(filteredData).map(([key, value]) => (
                <CreditsBreakdown
                  key={key}
                  data={value}
                  fixedColumns={fixedColumns}
                  tableTitle={key
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, char => char.toUpperCase())}
                  includeTotalColumns={true}
                />
              ))}
            </>
          )}

          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            className="banking-modal-content"
            overlayClassName="banking-modal-overlay"
            contentLabel="Bank Transactions"
          >
            <button onClick={closeModal} className="banking-modal-close-button">&times;</button>
            {selectedPartyCategory && isModalOpen && (
              <BankingTransactions
                customer_uuid={customer_uuid}
                party_category={selectedPartyCategory}
                is_modal_view={true}
              />
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

export default BankingCashflowInsights;
