import React, { useState } from 'react';
import './GetStarted.css';
import logo from '../../brand.png';
import OnDemandAnalysis from '../OnDemandAnalysis/OnDemandAnalysis'; // Import OnDemandAnalysis component

const GetStarted = () => {
  const [formData, setFormData] = useState({
    email: '',
    otp: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false); // To track OTP sent status
  const [isOtpEnabled, setIsOtpEnabled] = useState(false); // To enable OTP input field
  const [isOtpSubmitted, setIsOtpSubmitted] = useState(false); // To track OTP submission status
  const [touchedFields, setTouchedFields] = useState({});
  const [isMinimized, setIsMinimized] = useState(false); // For OnDemandAnalysis
  const [otpMessage, setOtpMessage] = useState(''); // For OTP status messages

  // Email validation regex
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  // Handles input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handles when user leaves the input field
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouchedFields({ ...touchedFields, [name]: true });
  };

  // Simulate sending OTP to email
  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!isValidEmail(formData.email)) {
      setTouchedFields({ ...touchedFields, email: true });
      return;
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsOtpSent(true);
      setIsOtpEnabled(true); // Enable OTP input after sending
      setIsLoading(false);
      setOtpMessage('OTP has been sent to your email.');
    }, 1000);
  };

  // Handles submitting the OTP
  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsOtpSubmitted(true); // Enable OnDemandAnalysis after OTP submission
      setOtpMessage('OTP verified successfully.');
    }, 1000);
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className="get-started-container">
      <div className="left-half">
        <div className="logo-section">
          <img src={logo} alt="Company Logo" className="sidebar-logo" />
        </div>
        {!isOtpSubmitted ? (
          <form className="get-started-form">
            {/* Email Input */}
            <label>Email Address <span className="required">*</span></label>
            <input
              name="email"
              type="email"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter your email"
              className={
                (!formData.email && touchedFields.email) || (formData.email && !isValidEmail(formData.email)) 
                  ? 'input-error' 
                  : ''
              }
              disabled={isOtpSent} // Disable email input after OTP is sent
            />
            {touchedFields.email && !isValidEmail(formData.email) && (
              <p className="error-message">Please enter a valid email address.</p>
            )}

            {/* Button to Send OTP */}
            {!isOtpSent && (
              <div className="get-started-button-container">
                <button onClick={handleSendOtp} disabled={isLoading || !formData.email}>
                  {isLoading ? 'Sending OTP...' : 'Send OTP'}
                </button>
              </div>
            )}

            {/* OTP Input and Submit Button */}
            {isOtpEnabled && (
              <>
                <label>OTP <span className="required">*</span></label>
                <input
                  name="otp"
                  type="text"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter OTP"
                  maxLength="6"
                  className={formData.otp.length !== 6 && touchedFields.otp ? 'input-error' : ''}
                />

                <div className="get-started-button-container">
                  <button onClick={handleSubmitOtp} disabled={isLoading || formData.otp.length !== 6}>
                    {isLoading ? 'Submitting OTP...' : 'Submit OTP'}
                  </button>
                </div>
              </>
            )}

            {/* OTP status message */}
            {otpMessage && <p className="otp-status-message">{otpMessage}</p>}
          </form>
        ) : (
          <div className="user-info-section">
            <h2>Welcome {formData.email}!</h2>
            <p>Your identity has been verified successfully, and you are now ready to initiate your loan process.</p>
            
            <div className="loan-process-intro">
              <p>To proceed, we need to collect essential information and documents that will help us in processing your loan application.</p>
              <p>Our system will guide you through each step and keep you updated on the progress.</p>
            </div>
            
            <div className="next-steps">
              <h3>Next Steps in Your Loan Process</h3>
              <p>Here’s what you can do:</p>
              <ul>
                <li>Submit required documents for prequalification.</li>
                <li>Track your application’s progress in real time.</li>
                <li>Receive guidance on additional details or requirements.</li>
              </ul>
              <p>You can start by uploading documents or requesting further assistance.</p>
            </div>
          </div>
        )}
      </div>

      {isOtpSubmitted && (
        <div className="right-half">
          <OnDemandAnalysis 
            isMinimized={false} 
            toggleMinimize={toggleMinimize} 
            customer_uuid={null} // Keep this if you don't have customer_uuid yet
            email_id={formData.email} // Pass the email_id from formData
          />
        </div>
      )}
    </div>
  );
};

export default GetStarted;
