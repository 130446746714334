import { API_ROUTES } from "./apiConfig";
import Cookies from 'js-cookie';

const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

// const token = Cookies.get('access_token_cookie');


const fetchResponse = async (url, options) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Server responded with ${response.status}: ${errorBody}`);
    }
    return response; // Return the raw response to handle it outside
  } catch (error) {
    console.error("Fetch operation failed:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

const fetchData = async (url, options) => {
  try {
    const response = await fetch(url, options);
    if (response.status === 404) {
      return { status: 404 }; // Return a specific value for 404
    }
    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Server responded with ${response.status}: ${errorBody}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch operation failed:", error);
    throw error;
  }
};


export const uploadDocument = async (file, dataPoint,document_type_code, customerUuid,password_required, password) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("data_point", dataPoint);
  formData.append("password_required", password_required);
  formData.append("password", password);
  formData.append("document_type_code", document_type_code);
  formData.append("customer_uuid", customerUuid);

  return fetchData(API_ROUTES.upload, {
    // Adjust according to your `apiConfig`
    method: "POST",
    body: formData,
    headers: {
      token: getAccessToken(),
      client_id: 1, // Include the client_id in the request headers
      // Note: Since you're using FormData, you should NOT manually set Content-Type.
      // The browser will automatically set it correctly including the boundary parameter.
    },
  });
};

export const getUploadedFiles = async (customer_uuid, data_point) => {
  return fetchData(`${API_ROUTES.upload}${customer_uuid}/${data_point}`, {
    // Use template literal for dynamic URLs
    method: "GET",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
  });
};

export const getAnalysis = async (customer_uuid) => {
  return fetchData(`${API_ROUTES.analyze}/${customer_uuid}`, {
    // Use template literal for dynamic URLs
    method: "GET",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
  });
};

export const getStatus = async (task_id) => {
  return fetchData(`${API_ROUTES.taskStatus}/${task_id}`, {
    // Use template literal for dynamic URLs
    method: "GET",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
  });
};


export const getCategorizedBankTransactions = async (customer_uuid) => {
  return fetchData(`${API_ROUTES.categorizedBankTransaction}/${customer_uuid}`, {
    // Use template literal for dynamic URLs
    method: "GET",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
  });
};


export const performFraudCheck = async (customer_uuid) => {
  return fetchData(`${API_ROUTES.fraudCheck}/${customer_uuid}`, {
    // Use template literal for dynamic URLs
    method: "GET",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
  });
};


export const getBankBalanceDetails = async (customer_uuid, from_date, to_date) => {
  return fetchData(`${API_ROUTES.bankBalanceDetails}/${customer_uuid}?from_date=${from_date}&to_date=${to_date}`, {
    // Use template literal for dynamic URLs
    method: "GET",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
  });
};


export const getInsight = async (customer_uuid) => {
  return fetchData(`${API_ROUTES.insight}/${customer_uuid}`, {
    // Use template literal for dynamic URLs
    method: "GET",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
  });
};

export const analyzeData = async (customer_uuid) => {
  return fetchData(API_ROUTES.analyzeData, {
    // Adjust according to your `apiConfig`
    method: "POST",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
    body: JSON.stringify({
      customer_uuid: customer_uuid,
    }),
  });
};

export const userLogin = async (username, password) => {
  const options = {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: "1", // Make sure this is a string if it's not dynamic or just remove quotes if its value is numerical.
      token: getAccessToken(),
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
    credentials: "include",
  };

  const response = await fetchResponse(API_ROUTES.login, options);

  // If response is okay and we need to handle headers for cookies
  if (response.ok) {
    return {
      body: await response.json(), // assuming the body needs to be processed after confirming the response is OK
      success: true,
    };
  }

  // If the response is not OK, handle it according to your app's logic
  throw new Error(`Login failed with status: ${response.status}`);
};

export const get_answers = async (customer_uuid = null, email_id = null, question, sourceId = null) => {
  // Ensure that at least one of customer_uuid or email_id is provided
  if (!customer_uuid && !email_id) {
    throw new Error('Either customer_uuid or email_id must be provided.');
  }

  // Construct the payload, ensuring both customer_uuid and email_id are on the same level
  const payload = {
    customer_uuid: customer_uuid || null, // Add customer_uuid (null if not provided)
    email_id: email_id || null, // Add email_id (null if not provided)
    question: question, // Add question
    source_id: sourceId || null, // Add source_id (null if not provided)
  };

  // Remove keys with null values to avoid sending them in the request payload
  const filteredPayload = Object.keys(payload).reduce((acc, key) => {
    if (payload[key] !== null) acc[key] = payload[key];
    return acc;
  }, {});

  return fetchData(API_ROUTES.onDemandAnalysis, {
    method: "POST",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
    body: JSON.stringify(filteredPayload), // Convert the filtered payload to JSON string
  });
};





export const createCustomer = async (data) => {
  return fetchData(API_ROUTES.createCustomer, {
    // Adjust according to your `apiConfig`
    method: "POST",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
    body: JSON.stringify(data),
  });
};

export const getCustomerList = async () => {
  
  return fetchData(API_ROUTES.customerList, {
    // Adjust according to your `apiConfig`
    method: "GET",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
  });
};

export const getCustomerDetails = async (customer_uuid) => {
  return fetchData(`${API_ROUTES.customerDetails}/${customer_uuid}`, {
    // Use template literal for dynamic URLs
    method: "GET",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
  });
};

export const generateCamReport = async (data) => {
  return fetchData(API_ROUTES.generateCamReport, {
    // Adjust according to your `apiConfig`
    method: "POST",
    headers: {
      token: getAccessToken(),
      Accept: "*/*",
      "Content-Type": "application/json",
      client_id: 1,
    },
    body: JSON.stringify(data),
  });
};