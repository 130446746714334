import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCustomerList } from '../../api/apiServices';
import { FaFolderOpen } from 'react-icons/fa';
import './HomePage.css';
import Button from '../Button/Button';
import CustomerModal from '../CustomerModal/CustomerModal';
import Toast from '../Toast/Toast';
import Loader from '../Loader/Loader'; // Import the Loader component
import logo from '../../brand.png';
import VerticalTabs from '../VerticalTabs/VerticalTabs';
import AnalysisResponseComponent from '../recommendations/AnalysisResponse';

const HomePage = () => {
  const [customers, setCustomers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      fetchCustomers();
    }
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const accordionData = [
    {
      id: '1',
      title: 'All Borrowers',
      icon: <FaFolderOpen />,
      content: null
    }
  ];

  const handleCustomerCreated = async (message, isSuccess) => {
    setToastMessage(message);
    setShowToast(true);
    setToastType(isSuccess ? 'success' : 'failure');
    setTimeout(() => setShowToast(false), 3000);

    if (isSuccess) {
      await fetchCustomers();
    }
  };

  const convertToIST = (dateString) => {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    return date;
  };

  const formatDate = (dateString) => {
    const adjustedDate = convertToIST(dateString);
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit'
    };
    return adjustedDate.toLocaleString('en-US', options);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredCustomers = customers.filter(customer =>
    customer.name.toLowerCase().includes(searchTerm) ||
    customer.created_at.toLowerCase().includes(searchTerm)
  );

  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const customerList = await getCustomerList();
      setCustomers(customerList.data);
    } catch (error) {
      console.error("Failed to fetch customers:", error);
      
      // Check if the error is due to an invalid token
      if (error.response && error.response.status === 422 && error.response.data.error === 'invalid_token') {
        // Redirect to login
        navigate('/login');
      } else {
        // Handle other errors
        console.error('An unexpected error occurred:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  const visitCustomerDashboard = (customer) => {
    navigate(`/dashboard/${customer.uuid}`, {
      state: { customer },
    });
  };
  

  return (
    <div className='home-page' style={{ overflow: 'hidden' }}>
      <div className="home-sidebar">
        <div className="home-sidebar-header">
          <div className='home-sidebar-logo-container'>
            <img src={logo} alt="Kreditmind Logo" className="home-sidebar-logo" onClick={() => navigate('/')} />
          </div>
          <div className="home-sidebar-customer-name"></div>
        </div>
        <VerticalTabs items={accordionData} onItemSelect={toggleModal} />
      </div>

      <div className="home-container">
        <div className="stat-cards-container">
          {/* Dynamically created cards */}
        </div>

        <div className="customer-list">
          <div className="search-bar">
            <Button label="Add Borrower" onClick={toggleModal} />
            <input type="text" className="search-box" placeholder="Search..." onChange={handleSearchChange} />
          </div>
          <div className="customer-list-header">
            <div className="customer-name">Borrower Name</div>
            <div className="created-at">Created At</div>
            <div className="action"></div>
          </div>
          <div className="customer-list-body">
            {isLoading ? (
              <Loader />
            ) : (
              filteredCustomers.map((customer) => (
                <div key={customer.uuid} className="customer-item">
                <span>{customer.name}</span>
                <span>{formatDate(customer.created_at)}</span>
                <Button label="Open" onClick={() => visitCustomerDashboard(customer)} />
              </div>
              ))
            )}
          </div>
        </div>
        {isModalOpen && <CustomerModal onCloseModal={() => setIsModalOpen(false)} onCustomerCreated={handleCustomerCreated} />}
        {showToast && <Toast message={toastMessage} onClose={() => setShowToast(false)} type={toastType} />}
      </div>
    </div>
  );
};

export default HomePage;
