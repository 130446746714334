import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import './UploadModal.css';
import {
  AiOutlineClose,
  AiFillLock,
  AiFillUnlock,
  AiOutlineArrowRight,
} from 'react-icons/ai';
import * as pdfjsLib from 'pdfjs-dist/webpack'; // Use ES6 module

const UploadModal = ({ isOpen, onClose, onFileSelect }) => {
  const [files, setFiles] = useState([]);
  const [dataType, setDataType] = useState(null);
  const [bankType, setBankType] = useState(null);
  const [samePasswordForAll, setSamePasswordForAll] = useState(false);
  const [password, setPassword] = useState('');
  const [individualPasswords, setIndividualPasswords] = useState({});
  const [lockedFiles, setLockedFiles] = useState({});
  const [passwordError, setPasswordError] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    const allUnlocked = Object.values(lockedFiles).every(
      (status) => status === 'unlocked' || status === 'noPassword'
    );

    setPasswordError(
      allUnlocked ? '' : 'Some files are still locked. Please enter the correct password.'
    );

    // Reset same password for all when all files are unlocked
    if (allUnlocked) {
      setSamePasswordForAll(false);
    }
  }, [lockedFiles]);

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setLockedFiles({});
    setIndividualPasswords({});
    setPassword('');
    setPasswordError('');

    for (const file of selectedFiles) {
      if (file.type === 'application/pdf') {
        const protectionStatus = await checkPdfProtectionWithPdfJs(file);
        setLockedFiles((prev) => ({ ...prev, [file.name]: protectionStatus }));
      } else {
        setLockedFiles((prev) => ({ ...prev, [file.name]: 'noPassword' }));
      }
    }
  };

  const checkPdfProtectionWithPdfJs = async (file, enteredPassword = '') => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer, password: enteredPassword });
      await loadingTask.promise;

      return enteredPassword ? 'unlocked' : 'noPassword';
    } catch (error) {
      console.error('Error loading PDF with pdfjs-dist:', error.message);
      if (error.name === 'PasswordException' || error.message.includes('Unsupported encryption')) {
        return 'locked';
      }
      return 'error';
    }
  };

  const handleFileDelete = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    setLockedFiles((prevLocked) => {
      const updated = { ...prevLocked };
      delete updated[fileName];
      return updated;
    });
    setIndividualPasswords((prevPasswords) => {
      const updated = { ...prevPasswords };
      delete updated[fileName];
      return updated;
    });
  };

  const handleUnlockAll = async () => {
    const newLockedFiles = { ...lockedFiles };

    for (const file of files) {
      if (file.type === 'application/pdf' && lockedFiles[file.name] === 'locked') {
        const protectionStatus = await checkPdfProtectionWithPdfJs(file, password);
        newLockedFiles[file.name] = protectionStatus;
        if (protectionStatus === 'unlocked') {
          // Store the password used for all files when unlocking all with a common password
          setIndividualPasswords((prev) => ({
            ...prev,
            [file.name]: password,
          }));
        }
      }
    }

    setLockedFiles(newLockedFiles);

    // Reset passwords if all are unlocked
    if (Object.values(newLockedFiles).every((status) => status === 'unlocked')) {
      setPassword('');
    }
  };

  const handleUnlockIndividual = async (fileName) => {
    const file = files.find((f) => f.name === fileName);
    const enteredPassword = individualPasswords[fileName] || '';

    console.log(`Attempting to unlock file: ${fileName} with password: ${enteredPassword}`);

    if (file && file.type === 'application/pdf' && lockedFiles[file.name] === 'locked') {
      const protectionStatus = await checkPdfProtectionWithPdfJs(file, enteredPassword);
      setLockedFiles((prev) => ({ ...prev, [fileName]: protectionStatus }));

      console.log(`File: ${fileName} unlocked status: ${protectionStatus}`);

      if (protectionStatus === 'unlocked') {
        // Store the password used to unlock the file
        setIndividualPasswords((prev) => ({
          ...prev,
          [fileName]: enteredPassword,
        }));
      } else {
        setPasswordError(`Incorrect password for file ${fileName}.`);
      }
    }
  };

  const handlePasswordChange = (fileName, password) => {
    setIndividualPasswords((prev) => ({
      ...prev,
      [fileName]: password,
    }));
    console.log('Updated individual passwords:', { ...individualPasswords, [fileName]: password });
  };

  const handleSubmit = () => {
    if (!files.length || !dataType) {
      console.warn('No files or data type selected.');
      return;
    }

    // Prepare filesData ensuring we capture the most current state
    const filesData = files.map((file) => {
      const isLocked = lockedFiles[file.name] === 'locked';
      const isUnlocked = lockedFiles[file.name] === 'unlocked';

      // Accurately assign the correct password based on the most recent state
      let filePassword = '';
      if (samePasswordForAll) {
        // Use the common password if enabled
        filePassword = password;
      } else if (isUnlocked) {
        // Retrieve the stored password used to unlock the file
        filePassword = individualPasswords[file.name] || '';
      } else if (isLocked && individualPasswords[file.name]) {
        filePassword = individualPasswords[file.name]; // Assign password even if locked for submission
      }

      // Log file status and password for debugging
      console.log(`File: ${file.name}, Status: ${lockedFiles[file.name]}, Password: ${filePassword}`);

      return {
        file,
        password_required: isLocked || isUnlocked,
        password: filePassword, // Ensure the password is correct for each file
        dataType: dataType?.value,
        document_type_code: dataType?.value === 'bank' ? bankType?.value : null,
      };
    });

    console.log('Prepared filesData for submission:', filesData);

    if (filesData.length > 0 && dataType) {
      onFileSelect(filesData);
      onClose();

      // Reset all states after submission
      setFiles([]);
      setDataType(null);
      setBankType(null);
      setPassword('');
      setPasswordError('');
      setLockedFiles({});
      setIndividualPasswords({});
      setSamePasswordForAll(false);
    } else {
      console.warn('Submission conditions not met.');
    }
  };

  const handleDataTypeChange = (selectedOption) => {
    setDataType(selectedOption);
    if (selectedOption.value !== 'bank') {
      setBankType(null);
    }
  };

  const handleBankTypeChange = (selectedOption) => {
    setBankType(selectedOption);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: '16px',
      fontFamily: 'Inter',
      padding: '5px',
      borderColor: '#ddd',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#ccc',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '16px',
      fontFamily: 'Inter',
      backgroundColor: state.isSelected ? '#f0f0f0' : '#fff',
      color: '#333',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }),
  };

  if (!isOpen) return null;

  const anyLockedFiles = Object.values(lockedFiles).some((status) => status === 'locked');

  const truncateFileName = (name) => {
    const maxLength = 20;
    if (name.length <= maxLength) return name;
    const start = name.slice(0, 10);
    const end = name.slice(-8);
    return `${start}...${end}`;
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="modal-close-button" onClick={onClose}>
          <AiOutlineClose />
        </button>
        <div className="upload-file-label">Upload File</div>
        <div className="modal-section">
          <Select
            placeholder="Select Data Type"
            value={dataType}
            onChange={handleDataTypeChange}
            options={[
              { value: 'bank', label: 'Bank Statements' },
              { value: 'experian', label: 'Experian' },
              { value: 'gst', label: 'GST' },
              // ... other options ...
            ]}
            isSearchable
            styles={customSelectStyles}
          />
        </div>
        {dataType?.value === 'bank' && (
          <div className="modal-section">
            <Select
              placeholder="Select Bank"
              value={bankType}
              onChange={handleBankTypeChange}
              options={[
                {value:"ICICI",label: "ICICI"},
                {value:"SBI",label: "SBI"},
                {value:"KOTAK",label: "Kotak"},
                {value:"YES",label: "Yes"},
                {value:"YES_CORP",label: "Yes Corp"},
                {value:"HDFC",label: "HDFC"},
                {value:"AXIS",label: "Axis"},
                {value:"SYNDICATE",label: "Syndicate"},
                {value:"CORPORATION",label: "Corporation"},
                {value:"PNB",label: "PNB"},
                {value:"UNITED_BANK",label: "United Bank"},
                {value:"INDUSIND",label: "IndusInd"},
                {value:"CENTRAL_BANK",label: "Central Bank"},
                {value:"CITI",label: "Citi"},
                {value:"DBS",label: "DBS"},
                {value:"CANARA",label: "Canara"},
                {value:"STANDARD_CHARTERED",label: "Standard Chartered"},
                {value:"RBL",label: "RBL"},
                {value:"UNION_BANK",label: "Union Bank"},
                {value:"ALLAHABAD",label: "Allahabad"},
                {value:"BANK_OF_BARODA",label: "Bank of Baroda"},
                {value:"STATE_BANK_PATIALA",label: "State Bank of Patiala"},
                {value:"STATE_BANK_MYSORE",label: "State Bank of Mysore"},
                {value:"STATE_BANK_TRAVANCORE",label: "State Bank of Travancore"},
                {value:"TAMILNAD_MERCANTILE",label: "Tamilnad Mercantile"},
                {value:"STATE_BANK_BIKANER_JAIPUR",label: "State Bank of Bikaner and Jaipur"},
                {value:"STATE_BANK_HYDERABAD",label: "State Bank of Hyderabad"},
                {value:"BANK_OF_INDIA",label: "Bank of India"},
                {value:"BANK_OF_MAHARASHTRA",label: "Bank of Maharashtra"},
                {value:"BARODA_RAJASTHAN_KSHETRIYA_GRAMIN_BANK",label: "Baroda Rajasthan Kshetriya Gramin Bank"},
                {value:"DENA_BANK",label: "Dena Bank"},
                {value:"INDIAN_BANK",label: "Indian Bank"},
                {value:"INDIAN_OVERSEAS",label: "Indian Overseas"},
                {value:"ORIENTAL_BANK",label: "Oriental Bank"},
                {value:"PUNJAB_SIND",label: "Punjab and Sind"},
                {value:"UCO",label: "UCO Bank"},
                {value:"VIJAYA",label: "Vijaya Bank"},
                {value:"IDBI",label: "IDBI"},
                {value:"BANDHAN",label: "Bandhan Bank"},
                {value:"CATHOLIC_SYRIAN",label: "Catholic Syrian Bank"},
                {value:"CITY_UNION",label: "City Union Bank"},
                {value:"DHANLAXMI",label: "Dhanlaxmi Bank"},
                {value:"DCB",label: "DCB Bank"},
                {value:"FEDERAL",label: "Federal Bank"},
                {value:"IDFC",label: "IDFC Bank"},
                {value:"KARNATAKA",label: "Karnataka Bank"},
                {value:"JAMMU_KASHMIR",label: "Jammu and Kashmir Bank"},
                {value:"KARUR_VYASA",label: "Karur Vysya Bank"},
                {value:"LAKSHMI_VILAS",label: "Lakshmi Vilas Bank"},
                {value:"NAINITAL",label: "Nainital Bank"},
                {value:"SOUTH_INDIAN",label: "South Indian Bank"},
                {value:"RBS",label: "RBS"},
                {value:"SARASWAT_BANK",label: "Saraswat Bank"},
                {value:"TJSB",label: "TJSB Bank"},
                {value:"DNB",label: "DNB Bank"},
                {value:"DNS",label: "DNS Bank"},
                {value:"DEUTSCHE_BANK",label: "Deutsche Bank"},
                {value:"GREATER_BOMBAY",label: "Greater Bombay Bank"},
                {value:"APNA_SAHAKARI_BANK",label: "Apna Sahakari Bank"},
                {value:"AKHAND_ANAND",label: "Akhand Anand Co-operative Bank"},
                {value:"ADARSH_CO_OPERATIVE_URBAN_BANK",label: "Adarsh Co-operative Urban Bank"},
                {value:"BASSEIN_CATHOLIC",label: "Bassein Catholic Co-operative Bank"},
                {value:"ANDHRA_BANK",label: "Andhra Bank"},
                {value:"AMBERNATH_JAI_HIND_CO_OP_BANK",label: "Ambernath Jai Hind Co-op Bank"},
                {value:"PUNJAB_MAHARASHTRA_CO_OP_BANK",label: "Punjab Maharashtra Co-op Bank"},
                {value:"HSBC",label: "HSBC Bank"},
                {value:"BHARAT",label: "Bharat Bank"},
                {value:"SVC_BANK",label: "SVC Bank"},
                {value:"SDC",label: "SDC Bank"},
                {value:"SREENIDHI_SOUHARDA_SAHAKARI_BANK",label: "Sreenidhi Souharda Sahakari Bank"},
                {value:"OCEAN_FIRST",label: "Ocean First Bank"},
                {value:"CAPITAL_SMALL_FINANCE_BANK_LTD",label: "Capital Small Finance Bank Ltd"},
                {value:"KALUPUR_COMMERCIAL_CO_OP_BANK",label: "Kalupur Commercial Co-op Bank"},
                {value:"KANGRA_CO_OP_BANK",label: "Kangra Co-op Bank"},
                {value:"BHARTIYA_MAHILA_BANK",label: "Bhartiya Mahila Bank"},
                {value:"SARDAR_BHILADWALA_PARDI_PEOPLES_CO_OP_BANK",label: "Sardar Bhiladwala Pardi Peoples Co-op Bank"},
                {value:"MODEL_CO_OPERATIVE_BANK",label: "Model Co-operative Bank"},
                {value:"MEHSANA_URBAN_CO_OP_BANK",label: "Mehsana Urban Co-op Bank"},
                {value:"NEW_INDIA_CO_OP_BANK",label: "New India Co-op Bank"},
                {value:"THE_NARODA_NAGRIK_CO_OP_BANK",label: "The Naroda Nagrik Co-op Bank"},
                {value:"COSMOS_BANK",label: "Cosmos Bank"},
                {value:"JANAKALYAN_SAHAKARI_BANK",label: "Janakalyan Sahakari Bank"},
                {value:"JANATA_SAHAKARI_BANK",label: "Janata Sahakari Bank"},
                {value:"MJALGAON_JANATA_SAHAKARI_BANK",label: "Mjalgaon Janata Sahakari Bank"},
                {value:"MANVI_PATTANA_SOUHARADA_SAHAKARI_BANK",label: "Manvi Pattana Souharada Sahakari Bank"},
                {value:"CENTRAL_CO_OPERATIVE_BANK",label: "Central Co-operative Bank"},
                {value:"NUTAN_NAGARIK_SAHAKARI_BANK",label: "Nutan Nagarik Sahakari Bank"},
                {value:"KALYAN_JANATA_SAHAKARI_BANK",label: "Kalyan Janata Sahakari Bank"},
                {value:"ABHYUDAYA_CO_OP_BANK",label: "Abhyudaya Co-op Bank"},
                {value:"AIRTEL_BANK",label: "Airtel Bank"},
                {value:"VANGHEE",label: "Vanghee Bank"},
                {value:"EQUITAS_SMALL_FINANCE_BANK",label: "Equitas Small Finance Bank"},
                {value:"AU_SMALL_FINANCE_BANK",label: "AU Small Finance Bank"},
                {value:"NAGPUR_NAGARIK_SAHAKARI_BANK",label: "Nagpur Nagarik Sahakari Bank"},
                {value:"TEXTILE_CO_OPERATIVE_BANK",label: "Textile Co-operative Bank"},
                {value:"JANASEVA_SAHAKARI_BANK",label: "Janaseva Sahakari Bank"},
                {value:"SARASPUR_NAGARIK_CO_OP_BANK",label: "Saraspur Nagarik Co-op Bank"},
                {value:"GP_PARSIK_BANK",label: "GP Parsik Bank"},
                {value:"TGMC_BANK",label: "TGMC Bank"},
                {value:"SARVODAYA_SAHAKARI_BANK",label: "Sarvodaya Sahakari Bank"},
                {value:"SHREE_KADI_NAGARIK_SAHAKARI_BANK",label: "Shree Kadi Nagarik Sahakari Bank"},
                {value:"SAURASHTRA_GRAMIN_BANK",label: "Saurashtra Gramin Bank"},
                {value:"ASSOCIATE_CO_OP_BANK",label: "Associate Co-op Bank"},
                {value:"SOLAPUR_JANATA_SAHAKARI_BANK",label: "Solapur Janata Sahakari Bank"},
                {value:"INDRAPRASTHA_SEHKARI_BANK",label: "Indraprastha Sehkari Bank"},
                {value:"MAHESH_SAHAKARI_BANK_LTD",label: "Mahesh Sahakari Bank Ltd"},
                {value:"PUNE_PEOPLES_CO_OP_BANK",label: "Pune Peoples Co-op Bank"},
                {value:"VASAI_VIKAS_SAH_BANK_LTD",label: "Vasai Vikas Sah Bank Ltd"},
                {value:"MAHESH_BANK",label: "Mahesh Bank"},
                {value:"UNION_CO_OP_BANK_LTD",label: "Union Co-op Bank Ltd"},
                {value:"JANATHA_SEVA_CO_OP_BANK",label: "Janatha Seva Co-op Bank"},
                {value:"THE_WAI_URBAN_CO_OPERATIVE_BANK_LIMITED",label: "The Wai Urban Co-operative Bank Limited"},
                {value:"THE_VALLABH_VIDYANAGAR_COMMERCIAL_CO_OPERATIVE_BANK_LTD",label: "The Vallabh Vidyanagar Commercial Co-operative Bank Ltd"},
                {value:"THE_VIJAY_CO_OP_BANK",label: "The Vijay Co-op Bank"},
                {value:"ZOROASTRIAN_CO_OP_BANK",label: "Zoroastrian Co-op Bank"},
                {value:"VAIJAPUR_MARCHANTS_CO_OP_BANK",label: "Vaijapur Merchants Co-op Bank"},
                {value:"NKGSB_CO_OP_BANK",label: "NKGSB Co-op Bank"},
                {value:"THE_SUTEX_CO_OP_BANK",label: "The Sutex Co-op Bank"},
                {value:"THE_SURAT_PEOPLE_CO_OP_BANK_LTD",label: "The Surat People Co-op Bank Ltd"},
                {value:"GUJARAT_AMBUJA_CO_OP_BANK_LTD",label: "Gujarat Ambuja Co-op Bank Ltd"},
                {value:"KANKARIAA_MANINAGAR_NAG_SAH_BANK_LTD",label: "Kankariaa Maninagar Nag Sah Bank Ltd"},
                {value:"UJJIVAN_SMALL_FINANCE_BANK",label: "Ujjivan Small Finance Bank"},
                {value:"CITIZEN_CREDIT_CO_OP_BANK",label: "Citizen Credit Co-op Bank"},
                {value:"PADMAVATHI_CO_OP_URBAN_BANK",label: "Padmavathi Co-op Urban Bank"},
                {value:"THE_SATARA_SAHAKARI_BANK_LTD",label: "The Satara Sahakari Bank Ltd"},
                {value:"MAHANAGAR_CO_OP_BANK_LTD",label: "Mahanagar Co-op Bank Ltd"},
                {value:"SURYODAY_SMALL_FINANCE_BANK_LTD",label: "Suryoday Small Finance Bank Ltd"},
                {value:"SHREE_CO_OP_BANK_LTD",label: "Shree Co-op Bank Ltd"},
                {value:"MANINAGAR_CO_OP_BANK_LTD",label: "Maninagar Co-op Bank Ltd"},
                {value:"THE_VARACHHA_CO_OP_BANK_LTD",label: "The Varachha Co-op Bank Ltd"},
                {value:"SURAT_NATIONAL_CO_OP_BANK_LTD",label: "Surat National Co-op Bank Ltd"},
                {value:"VIDYA_SAHKARI_BANK_LTD",label: "Vidya Sahkari Bank Ltd"},
                {value:"PRERANA_CO_OP_BANK",label: "Prerana Co-op Bank"},
                {value:"THE_KARAD_URBAN_CO_OP_BANK_LTD",label: "The Karad Urban Co-op Bank Ltd"},
                {value:"THE_PANCHSHEEL_MERCANTILE_CO_OP_BANK_LTD",label: "The Panchsheel Mercantile Co-op Bank Ltd"},
                {value:"MAGAVEERA_CO_OP_BANK_LTD",label: "Magaveera Co-op Bank Ltd"},
                {value:"THANE_BHARAT_SAHAKARI_BANK_LTD",label: "Thane Bharat Sahakari Bank Ltd"},
                {value:"SREE_MAHAYOGI_LAKSHMAMMA_CO_OP_BANK_LTD",label: "Sree Mahayogi Lakshmamma Co-op Bank Ltd"},
                {value:"VIDARBHA_MERCHANTS_URBAN_CO_OP_BANK_LTD",label: "Vidarbha Merchants Urban Co-op Bank Ltd"},
                {value:"PRATHAMA_BANK",label: "Prathama Bank"},
                {value:"PRIME_BANK",label: "Prime Bank"},
                {value:"DR_BABASAHEB_AMBEDKAR_MULTISTATE_CO_OP_BANK",label: "Dr. Babasaheb Ambedkar Multistate Co-op Bank"},
                {value:"THE_HINDUSTHAN_CO_OP_BANK_LTD",label: "The Hindusthan Co-op Bank Ltd"},
                {value:"KARNAVATI_CO_OP_BANK",label: "Karnavati Co-op Bank"},
                {value:"THE_AHMEDNAGAR_MERCHANT_CO_OP_BANK_LTD",label: "The Ahmednagar Merchant Co-op Bank Ltd"},
                {value:"THE_KANAKAMAHALAKSHMI_CO_OP_BANK_LTD",label: "The Kanakamahalakshmi Co-op Bank Ltd"},
                {value:"KURMANCHAL_BANK",label: "Kurmanchal Bank"},
                {value:"THE_GANDHIDHAM_COOPERATIVE_BANK_LTD",label: "The Gandhidham Cooperative Bank Ltd"},
                {value:"SHRI_ARIHANT_COOP_BANK",label: "Shri Arihant Coop Bank"},
                {value:"KALLAPPANNA_AWADE_BANK",label: "Kallappanna Awade Bank"},
                {value:"SAHYDRI_SAHAKARI_BANK",label: "Sahydri Sahakari Bank"},
                {value:"BIHAR_GRAMIN_BANK",label: "Bihar Gramin Bank"},
                {value:"ANNASAHAB_MAGAR_SAHAKARI_BANK",label: "Annasahab Magar Sahakari Bank"},
                {value:"THE_VISHWESHWAR_SAHAKARI_BANK_LTD",label: "The Vishweshwar Sahakari Bank Ltd"},
                {value:"PURVANCHAL_BANK",label: "Purvanchal Bank"},
                {value:"THE_RAJKOT_COMMERCIAL_COOP_BANK",label: "The Rajkot Commercial Coop Bank"},
                {value:"THE_JALGAON_PEOPLES_COOP_BANK",label: "The Jalgaon Peoples Coop Bank"},
                {value:"AHMEDABAD_DISTRICT_CO_OP_BANK",label: "Ahmedabad District Co-op Bank"},
                {value:"SHIVALIK_MERCANTILE_BANK",label: "Shivalik Mercantile Bank"},
                {value:"THE_AKOLA_DISTRICT_CENTRAL_CO_OP_BANK",label: "The Akola District Central Co-op Bank"},
                {value:"THE_PANIPAT_URBAN_CO_OP_BANK",label: "The Panipat Urban Co-op Bank"},
                {value:"SHRI_RAJKOT_DISTRICT_CO_OP_BANK",label: "Shri Rajkot District Co-op Bank"},
                {value:"NEELKANT_CO_OP_BANK",label: "Neelkanth Co-op Bank"},
                {value:"THE_EENADU_CO_OP_URBAN_BANK",label: "The Eenadu Co-op Urban Bank"},
                {value:"THE_AHMEDABAD_MERCANTILE_CO_OP_BANK",label: "The Ahmedabad Mercantile Co-op Bank"},
                {value:"ADARSH_CO_OPERATIVE_BANK_LTD",label: "Adarsh Co-operative Bank Ltd"},
                {value:"KARNATAKA_VIKAS_GRAMEENA_BANK",label: "Karnataka Vikas Grameena Bank"},
                {value:"NOIDA_COMMERCIAL_CO_OP_BANK",label: "Noida Commercial Co-op Bank"},
                {value:"NAV_JEEVAN_CO_OP_BANK",label: "Nav Jeevan Co-op Bank"},
                {value:"JAI_TULJABHAVANI_URBAN_CO_OP_BANK",label: "Jai Tuljabhavani Urban Co-op Bank"},
                {value:"THE_BARDOLI_NAGARIK_SAHAKARI_BANK",label: "The Bardoli Nagarik Sahakari Bank"},
                {value:"RMGB",label: "RMGB"},
                {value:"STERLING_URBAN_CO_OP_BANK",label: "Sterling Urban Co-op Bank"},
                {value:"SMRITI_NAGRIK_SAHKARI_BANK",label: "Smriti Nagrik Sahkari Bank"},
                {value:"FINO_PAYMENTS_BANK",label: "Fino Payments Bank"},
                {value:"VAISHYA_NAGARI_SAHAKARI_BANK",label: "Vaishya Nagari Sahakari Bank"},
                {value:"VANANCHAL_GRAMIN_BANK",label: "Vananchal Gramin Bank"},
                {value:"UTKARSH_SMALL_FINANCE_BANK",label: "Utkarsh Small Finance Bank"},
                {value:"MAHARASHTRA_GRAMIN_BANK",label: "Maharashtra Gramin Bank"},
                {value:"UTTARAKHAND_GRAMIN_BANK",label: "Uttarakhand Gramin Bank"},
                {value:"BHAGYODAYA_CO_OP_BANK",label: "Bhagyodaya Co-op Bank"},
                {value:"BANK_OF_AMERICA",label: "Bank of America"},
                {value:"MANORAMA_CO_OP_BANK_SOLAPUR",label: "Manorama Co-op Bank Solapur"},
                {value:"SHREE_MAHESH_CO_OP_BANK_NASHIK",label: "Shree Mahesh Co-op Bank Nashik"},
                {value:"SANGOLA_URBAN_CO_OPERATIVE_BANK_LTD",label: "Sangola Urban Co-operative Bank Ltd"},
                {value:"GUJARAT_STATE_CO_OP_BANK",label: "Gujarat State Co-op Bank"},
                {value:"DAHOD_URBAN_CO_OP_BANK",label: "Dahod Urban Co-op Bank"},
                {value:"SANMATHI_SAHAKARI_BANK",label: "Sanmathi Sahakari Bank"},
                {value:"JANA_SMALL_FINANCE_BANK",label: "Jana Small Finance Bank"},
                {value:"ADINATH_CO_OP_BANK",label: "Adinath Co-op Bank"},
                {value:"DECCAN_MERCHANTS_CO_OP_BANK",label: "Deccan Merchants Co-op Bank"},
                {value:"SEVA_VIKAS_CO_OP_BANK",label: "Seva Vikas Co-op Bank"},
                {value:"VIKAS_SAHAKARI_BANK",label: "Vikas Sahakari Bank"},
                {value:"ABASAHEB_PATIL_RENDAL_SAHAKARI_BANK",label: "Abasaheb Patil Rendal Sahakari Bank"},
                {value:"SAMATA_SAHAKARI_BANK",label: "Samata Sahakari Bank"},
                {value:"JAMIA_CO_OP_BANK",label: "Jamia Co-op Bank"},
                {value:"DAPOLI_URBAN_CO_OP_BANK",label: "Dapoli Urban Co-op Bank"},
                {value:"PUNE_URBAN_CO_OP_BANK",label: "Pune Urban Co-op Bank"},
                {value:"THE_SIND_CO_OP_URBAN_BANK",label: "The Sind Co-op Urban Bank"},
                {value:"POCHAMPALLY_BANK",label: "Pochampally Bank"},
                {value:"SARVODAYA_COMMERICIAL_CO_OP_BANK",label: "Sarvodaya Commercial Co-op Bank"},
                {value:"HIMACHAL_PRADESH_STATE_CO_OP_BANK",label: "Himachal Pradesh State Co-op Bank"},
                {value:"KEB_HANA_BANK",label: "KEB Hana Bank"},
                {value:"NAGAR_URBAN_CO_OP_BANK",label: "Nagar Urban Co-op Bank"},
                {value:"SUCO_SOUHARDA_SAHAKARI_BANK",label: "Suco Souharda Sahakari Bank"},
                {value:"ARVIND_SAHAKARI_BANK",label: "Arvind Sahakari Bank"},
                {value:"GANDHINAGAR_URBAN_CO_OP_BANK",label: "Gandhinagar Urban Co-op Bank"},
                {value:"MALAD_SAHAKARI_BANK",label: "Malad Sahakari Bank"},
                {value:"BOMBAY_MERCANTILE_CO_OP_BANK",label: "Bombay Mercantile Co-op Bank"},
                {value:"RAJARSHI_SHAHU_SAHAKARI_BANK",label: "Rajarshi Shahu Sahakari Bank"},
                {value:"MADHESHWARI_URBAN_DEVELOPMENT_CO_OP_BANK",label: "Madheshwari Urban Development Co-op Bank"},
                {value:"NIDHI_CO_OP_BANK",label: "Nidhi Co-op Bank"},
                {value:"URBAN_CO_OP_BANK_BUDAUN",label: "Urban Co-op Bank Budaun"},
                {value:"DMK_JAOLI_BANK",label: "DMK Jaoli Bank"},
                {value:"VARDHAMAN_MAHILA_CO_OP_URBAN_BANK",label: "Vardhaman Mahila Co-op Urban Bank"},
                {value:"JAYSINGPUR_UDGAON_SAHAKARI_BANK_JAYSINGPUR",label: "Jaysingpur Udgaon Sahakari Bank Jaysingpur"},
                {value:"SANGLI_URBAN_CO_OP_BANK",label: "Sangli Urban Co-op Bank"},
                {value:"SHREE_DHARATI_CO_OP_BANK",label: "Shree Dharati Co-op Bank"},
                {value:"PRATAP_CO_OP_BANK",label: "Pratap Co-op Bank"},
                {value:"NASIK_ROAD_DEOLALI_VYAPARI_SAHAKARI_BANK",label: "Nasik Road Deolali Vyapari Sahakari Bank"},
                {value:"SARANGPUR_CO_OP_BANK",label: "Sarangpur Co-op Bank"},
                {value:"JAIHIND_URBAN_CO_OP_BANK",label: "Jaihind Urban Co-op Bank"},
                {value:"SAIBABA_NAGARI_SAHAKARI_BANK",label: "Saibaba Nagari Sahakari Bank"},
                {value:"THE_CHEMBUR_NAGRIK_SAHAKARI_BANK",label: "The Chembur Nagrik Sahakari Bank"},
                {value:"OCBC_SINGAPORE",label: "OCBC Singapore"},
                {value:"FINGROWTH_CO_OP_BANK",label: "Fingrowth Co-op Bank"},
                {value:"COASTAL_LOCAL_AREA_BANK",label: "Coastal Local Area Bank"},
                {value:"MAHANAGAR_NAGRIK_SAHAKARI_BANK",label: "Mahanagar Nagrik Sahakari Bank"},
                {value:"ESAF_SMALl_FINANCE_BANK",label: "ESAF Small Finance Bank"},
                {value:"RABO_BANK",label: "Rabo Bank"},
                {value:"THE_UDAIPUR_URBAN_CO_OP_BANK",label: "The Udaipur Urban Co-op Bank"},
                {value:"AMERICAN_EXPRESS",label: "American Express"},
                {value:"THE_GAYATRI_CO_OP_URBAN_BANK",label: "The Gayatri Co-op Urban Bank"},
                {value:"PROGRESSIVE_MERCANTILE_CO_OP_BANK",label: "Progressive Mercantile Co-op Bank"},
                {value:"TELANGANA_GRAMEENA_BANK",label: "Telangana Grameena Bank"},
                {value:"WARDHAMAN_URBAN_CO_OPERATIVE_BANK_LTD",label: "Wardhaman Urban Co-operative Bank Ltd"},
                {value:"KOKAN_MERCANTILE_CO_OPERATIVE_BANK",label: "Kokan Mercantile Co-operative Bank"},
                {value:"DAG",label: "DAG Bank"},
                {value:"NAVI_MUMBAI_CO_OP_BANK",label: "Navi Mumbai Co-op Bank"},
                {value:"FINCARE_SMALL_FINANCE_BANK",label: "Fincare Small Finance Bank"},
                {value:"PAYTM_BANK",label: "Paytm Bank"},
                {value:"THE_NAVNIRMAN_CO_OP_BANK_LTD",label: "The Navnirman Co-op Bank Ltd"},
                {value:"THE_MUNICIPAL_CO_OP_BANK",label: "The Municipal Co-op Bank"},
                {value:"BARODA_GUJARAT_GRAMIN_BANK",label: "Baroda Gujarat Gramin Bank"},
                {value:"CITIZENS_CO_OP_BANK",label: "Citizens Co-op Bank"},
                {value:"RAJKOT_NAGARIK_SAHAKARI_BANK_LTD",label: "Rajkot Nagarik Sahakari Bank Ltd"},
                {value:"SUVARNAYUG_SAHAKARI_BANK",label: "Suvarnayug Sahakari Bank"},
                {value:"SADHANA_SAHAKARI_BANK_LTD",label: "Sadhana Sahakari Bank Ltd"},
                {value:"MSANT_SOPANKAKA_SAHAKARI_BANK_LTD",label: "Msant Sopankaka Sahakari Bank Ltd"},
                {value:"MSHARAD_SAHAKARI_BANK_LTD",label: "Msharad Sahakari Bank Ltd"},
                {value:"THE_COMMERCIAL_CO_OP_BANK_LTD",label: "The Commercial Co-op Bank Ltd"},
                {value:"THE_LIMBASI_URBAN_CO_OP_BANK",label: "The Limbasi Urban Co-op Bank"},
                {value:"SAWJI_BANK",label: "Sawji Bank"},
                {value:"THE_CO_OP_BANK_OF_RAJKOT",label: "The Co-op Bank of Rajkot"},
                {value:"RAJARAMBAPU_SAHAKARI_BANK_LTD",label: "Rajarambapu Sahakari Bank Ltd"},
                {value:"DOMBIVILI_NAGARI_SAHAKARI_BANK_LIMITED",label: "Dombivili Nagari Sahakari Bank Limited"},
                {value:"S_S_L_S_A_KURUNDWAD_URBAN_BANK",label: "S S L S A Kurundwad Urban Bank"},
                {value:"PARSHWANATH_CO_OP_BANK_LTD",label: "Parshwanath Co-op Bank Ltd"},
                {value:"PRAGATHI_CO_OPERATIVE_BANK_LTD",label: "Pragathi Co-operative Bank Ltd"},
                {value:"THE_MUSLIM_CO_OP_BANK_LTD",label: "The Muslim Co-op Bank Ltd"},
                {value:"LOKMANGAL_CO_OP_BANK_LTD",label: "Lokmangal Co-op Bank Ltd"},
                {value:"THE_KARNATAKA_STATE_COOPERATIVE_APEX_BANK_LTD",label: "The Karnataka State Cooperative Apex Bank Ltd"},
                {value:"THE_FINANCIAL_CO_OP_BANK_LTD",label: "The Financial Co-op Bank Ltd"},
                {value:"THE_THANE_DISTRICT_CENTRAL_CO_OP_BANK_LTD",label: "The Thane District Central Co-op Bank Ltd"},
                {value:"AMBAJOGAI_PEOPLES_CO_OP_BANK_LTD",label: "Ambajogai Peoples Co-op Bank Ltd"},
                {value:"DEOGIRI_NAGARI_SAHAKARI_BANK_LTD",label: "Deogiri Nagari Sahakari Bank Ltd"},
                {value:"THE_BARAMATI_SAHAKARI_BANK_LTD",label: "The Baramati Sahakari Bank Ltd"},
                {value:"THE_SURAT_DISTRICT_CO_OP_BANK_LTD",label: "The Surat District Co-op Bank Ltd"},
                {value:"BANK_OF_BHUTAN",label: "Bank of Bhutan"},
                {value:"BHUTAN_NATIONAL_BANK",label: "Bhutan National Bank"},
                {value:"T_BANK_LIMITED",label: "T Bank Limited"},
                {value:"AJARA_URBAN_CO_OP_BANK_LTD",label: "Ajara Urban Co-op Bank Ltd"},
                {value:"CHAITANYA_GODAVARI_GRAMEENA_BANK",label: "Chaitanya Godavari Grameena Bank"},
                {value:"RUPICA_CO_OP_SOCIETY_LTD",label: "Rupica Co-op Society Ltd"},
                {value:"KARNATAKA_GRAMINA_BANK",label: "Karnataka Gramina Bank"},
                {value:"ANDHRA_PRAGATHI_GRAMEENA_BANK",label: "Andhra Pragathi Grameena Bank"},
                {value:"THE_VISAKHAPATNAM_CO_OP_BANK_LTD",label: "The Visakhapatnam Co-op Bank Ltd"},
                {value:"TUMKUR_GRAIN_MERCHANTS_CO_OP_BANK",label: "Tumkur Grain Merchants Co-op Bank"},
                {value:"GANDHIBAG_SAHAKARI_BANK_LTD",label: "Gandhibag Sahakari Bank Ltd"},
                {value:"JALNA_MERCHANTS_CO_OP_BANK",label: "Jalna Merchants Co-op Bank"},
                {value:"SAMARTH_SAHKARI_BANK_LTD",label: "Samarth Sahkari Bank Ltd"},
                {value:"THE_LAXMI_CO_OP_BANK_LTD",label: "The Laxmi Co-op Bank Ltd"},
                {value:"THE_MALLESHWARAM_CO_OP_BANK_LTD",label: "The Malleshwaram Co-op Bank Ltd"},
                {value:"NASIK_MERCHANT_CO_OP_BANK",label: "Nasik Merchant Co-op Bank"},
                {value:"MARATHA_CO_OPERATIVE_BANK",label: "Maratha Co-operative Bank"},
                {value:"THE_AURANGABAD_DISTRICT_CENTRAL_CO_OP_BANK",label: "The Aurangabad District Central Co-op Bank"},
                {value:"THE_SOCIAL_CO_OP_BANK_LTD",label: "The Social Co-op Bank Ltd"},
                {value:"INTEGRAL_URBAN_CO_OPERATIVE_BANK",label: "Integral Urban Co-operative Bank"},
                {value:"SHRI_CHHATRAPATI_RAJARSHI_SHAHU_URBAN_CO_OPERATIVE_BANK",label: "Shri Chhatrapati Rajarshi Shahu Urban Co-operative Bank"},
                {value:"SANGLI_DISTRICT_CENTRAL_CO_OP_BANK",label: "Sangli District Central Co-op Bank"},
                {value:"SHREE_TALAJA_NAGARIK_SAHAKARI_BANK_LTD",label: "Shree Talaja Nagarik Sahakari Bank Ltd"},
                {value:"SHRI_MAHAVIR_URBAN_CO_OP_BANK_LTD",label: "Shri Mahavir Urban Co-op Bank Ltd"},
                {value:"THE_KAIRA_DIST_CENTRAL_CO_OP_BANK_LTD",label: "The Kaira Dist Central Co-op Bank Ltd"},
                {value:"THE_KONARK_URBAN_CO_OP_BANK_LTD",label: "The Konark Urban Co-op Bank Ltd"},
                {value:"THE_DELHI_STATE_CO_OP_BANK_LTD",label: "The Delhi State Co-op Bank Ltd"},
                {value:"PAVANA_SAHAKARI_BANK_LTD",label: "Pavana Sahakari Bank Ltd"},
                {value:"PIMPRI_CHINCHWAD_SAHAKARI_BANK",label: "Pimpri Chinchwad Sahakari Bank"},
                {value:"INDIA_POST_PAYMENTS_BANK",label: "India Post Payments Bank"},
                {value:"THE_KOLHAPUR_URBAN_CO_OP_BANK_LTD",label: "The Kolhapur Urban Co-op Bank Ltd"},
                {value:"THE_CHIKHLI_URBAN_CO_OP_BANK",label: "The Chikhli Urban Co-op Bank"},
                {value:"GS_MAHANAGAR_CO_OP_BANK",label: "GS Mahanagar Co-op Bank"},
                {value:"THE_TEXCO_CO_OP_BANK",label: "The Texco Co-op Bank"},
                {value:"THE_ABHINAV_SAHAKARI_BANK_LTD",label: "The Abhinav Sahakari Bank Ltd"},
                {value:"THE_JAIN_SAHAKARI_BANK_LTD",label: "The Jain Sahakari Bank Ltd"},
                {value:"THE_MALKAPUR_URBAN_CO_OP_BANK",label: "The Malkapur Urban Co-op Bank"},
                {value:"SHREE_SHARADA_SAHAKARI_BANK_LTD",label: "Shree Sharada Sahakari Bank Ltd"},
                {value:"KURLA_NAGRIK_SAHAKARI_BANK_LTD",label: "Kurla Nagrik Sahakari Bank Ltd"},
                {value:"THE_TAMIL_NADU_STATE_APEX_CO_OP_BANK",label: "The Tamil Nadu State Apex Co-op Bank"},
                {value:"THE_ICHALKARANJI_MERCHANTS_CO_OP_BANK_LTD",label: "The Ichalkaranji Merchants Co-op Bank Ltd"},
                {value:"VASAI_JANATA_SAHAKARI_BANK_LTD",label: "Vasai Janata Sahakari Bank Ltd"},
                {value:"VYSYA_CO_OP_BANK_LTD",label: "Vysya Co-op Bank Ltd"},
                {value:"THE_NATIONAL_CO_OP_BANK_LTD",label: "The National Co-op Bank Ltd"},
                {value:"STATE_BANK_OF_MAURITIUS",label: "State Bank of Mauritius"},
                {value:"DHARMAVIR_SAMBHAJI_URBAN_CO_OP_BANK_LTD",label: "Dharmavir Sambhaji Urban Co-op Bank Ltd"},
                {value:"THE_BAPUNAGAR_MAHILA_CO_OP_BANK_LTD",label: "The Bapunagar Mahila Co-op Bank Ltd"},
                {value:"THE_ANDHRA_PRADESH_STATE_CO_OP_BANK_LTD",label: "The Andhra Pradesh State Co-op Bank Ltd"},
                {value:"ANDHRA_PRADESH_GRAMEENA_VIKAS_BANK",label: "Andhra Pradesh Grameena Vikas Bank"},
                {value:"JUNAGADH_CO_OP_BANK",label: "Junagadh Co-op Bank"},
                {value:"GANDEVI_PEOPLE_CO_OP_BANK",label: "Gandevi People Co-op Bank"},
                {value:"SHREE_WARANA_SAHAKARI_BANK_LTD",label: "Shree Warana Sahakari Bank Ltd"},
                {value:"SAPTAGIRI_GRAMEENA_BANK",label: "Saptagiri Grameena Bank"},
                {value:"SHINHAN_BANK_INDIA",label: "Shinhan Bank India"},
                {value:"KOHINOOR_SAHAKARI_BANK_LTD",label: "Kohinoor Sahakari Bank Ltd"},
                {value:"VIKAS_SOUHARDA_CO_OP_BANK_LTD",label: "Vikas Souharda Co-op Bank Ltd"},
                {value:"THE_DAVANGERE_URBAN_CO_OP_BANK",label: "The Davangere Urban Co-op Bank"},
                {value:"THE_JANATA_CO_OP_BANK_LTD",label: "The Janata Co-op Bank Ltd"},
                {value:"THE_MAHARASHTRA_STATE_CO_OP_BANK_LTD",label: "The Maharashtra State Co-op Bank Ltd"},
                {value:"THE_KUKARWADA_NAGRIK_SAHAKARI_BANK",label: "The Kukarwada Nagrik Sahakari Bank"},
                {value:"ANDARSUL_URBAN_CO_OP_BANK",label: "Andarsul Urban Co-op Bank"},
                {value:"NATIONAL_MERCANTILE_CO_OP_BANK",label: "National Mercantile Co-op Bank"},
                {value:"PROGRESSIVE_CO_OP_BANK",label: "Progressive Co-op Bank"},
                {value:"PRAVARA_SAHAKARI_BANK_LTD",label: "Pravara Sahakari Bank Ltd"},
                {value:"KARIMNAGAR_DCC_BANK_LTD",label: "Karimnagar DCC Bank Ltd"},
                {value:"SRI_GANESH_CO_OP_BANK_LTD",label: "Sri Ganesh Co-op Bank Ltd"},
                {value:"SIDDAGANGA_URBAN_CO_OP_BANK_LTD",label: "Siddaganga Urban Co-op Bank Ltd"},
                {value:"SHIVA_SAHAKARI_BANK",label: "Shiva Sahakari Bank"},
                {value:"SHRI_VEERSHAIV_CO_OP_BANK",label: "Shri Veershaiv Co-op Bank"},
                {value:"SHRI_BHARAT_URBAN_CO_OP_BANK",label: "Shri Bharat Urban Co-op Bank"},
                {value:"RAJKOT_PEOPLES_CO_OP_BANK_LTD",label: "Rajkot Peoples Co-op Bank Ltd"},
                {value:"RAJLAXMI_URBAN_CO_OP_BANK_LTD",label: "Rajlaxmi Urban Co-op Bank Ltd"},
                {value:"SRI_KANNIKAPARAMESWARI_CO_OP_BANK_LTD",label: "Sri Kannikaparameswari Co-op Bank Ltd"},
                {value:"SHRI_SHIDDESHWAR_CO_OP_BANK_LTD",label: "Shri Siddeshwar Co-op Bank Ltd"},
                {value:"THE_KRANTHI_CO_OP_URBAN_BANK",label: "The Kranthi Co-op Urban Bank"},
                {value:"MUMBAI_DISTRICT_CENTRAL_CO_OP_BANK",label: "Mumbai District Central Co-op Bank"},
                {value:"NANDANI_SAHAKARI_BANK",label: "Nandani Sahakari Bank"},
                {value:"POORNAWADI_NAGRIK_SAHAKARI_BANK",label: "Poornawadi Nagrik Sahakari Bank"},
                {value:"TEXTILE_TRADERS_CO_OP_BANK_LTD",label: "Textile Traders Co-op Bank Ltd"},
                {value:"BELLAD_BAGEWADI_URBAN_SOUHARDA_SAHAKARI_BANK",label: "Bellad Bagewadi Urban Souharda Sahakari Bank"},
                {value:"HASSAN_DISTRICT_CO_OP_CENTRAL_BANK",label: "Hassan District Co-op Central Bank"},
                {value:"AMAN_SAHKARI_BANK",label: "Aman Sahakari Bank"},
                {value:"THE_VYANKATESHWARA_SAHAKARI_BANK",label: "The Vyankateshwara Sahakari Bank"},
                {value:"THE_SSK_CO_OP_BANK",label: "The SSK Co-op Bank"},
                {value:"THE_ANAND_MERCANTILE_CO_OP_BANK",label: "The Anand Mercantile Co-op Bank"},
                {value:"TIRUPATI_URBAN_CO_OP_BANK_LTD",label: "Tirupati Urban Co-op Bank Ltd"},
                {value:"THE_GUNTUR_CO_OP_URBAN_BANK",label: "The Guntur Co-op Urban Bank"},
                {value:"THE_JAMKHANDI_URBAN_CO_OP_BANK",label: "The Jamkhandi Urban Co-op Bank"},
                {value:"SHANKAR_NAGRIK_SAHAKARI_BANK",label: "Shankar Nagrik Sahakari Bank"},
                {value:"SHARAD_NAGARI_SAHAKARI_BANK",label: "Sharad Nagari Sahakari Bank"},
                {value:"SANGLI_SAHAKARI_BANK_LTD",label: "Sangli Sahakari Bank Ltd"},
                {value:"GADHINGLAJ_URBAN_CO_OP_BANK",label: "Gadhinglaj Urban Co-op Bank"},
                {value:"THE_BHAGYALAKSHMI_MAHILA_SAH_BANK_LTD",label: "The Bhagyalakshmi Mahila Sah Bank Ltd"},
                {value:"THE_YADAGIRI_LAKSHMI_NARASIMHA_SWAMY_COOP_URBAN_BANK",label: "The Yadagiri Lakshmi Narasimha Swamy Coop Urban Bank"},
                {value:"THE_NAWANAGAR_CO_OP_BANK",label: "The Nawanagar Co-op Bank"},
                {value:"THE_VITA_MERCHANTS_CO_OP_BANK",label: "The Vita Merchants Co-op Bank"},
                {value:"THE_KHATTRI_CO_OP_URBAN_BANK",label: "The Khattri Co-op Urban Bank"},
                {value:"THE_VAISH_CO_OP_ADARSH_BANK",label: "The Vaish Co-op Adarsh Bank"},
                {value:"TAMIL_NADU_GRAMA_BANK",label: "Tamil Nadu Grama Bank"},
                {value:"THE_VIJAYPUR_DISTRICT_CENTRAL_CO_OP_BANK",label: "The Vijaypur District Central Co-op Bank"},
                {value:"THE_BAPUJI_CO_OP_BANK_LTD",label: "The Bapuji Co-op Bank Ltd"},
                {value:"THE_ASHTA_PEOPLES_CO_OP_BANK",label: "The Ashta Peoples Co-op Bank"},
                {value:"THE_SHIMOGA_ARECANUT_MANDY_MERCHANTS_CO_OP_BANK",label: "The Shimoga Arecanut Mandy Merchants Co-op Bank"},
                {value:"THE_VAISH_CO_OP_NEW_BANK",label: "The Vaish Co-op New Bank"},
                {value:"SHRI_CHATRAPATI_SHIVAJI_MAHARAJ_SAHAKARI_BANK",label: "Shri Chatrapati Shivaji Maharaj Sahakari Bank"},
                {value:"SHRI_MAHALAXMI_CO_OP_BANK",label: "Shri Mahalaxmi Co-op Bank"},
                {value:"SHARAD_SAHAKARI_BANK",label: "Sharad Sahakari Bank"},
                {value:"VIJAYPUR_SAHAKARI_BANK",label: "Vijaypur Sahakari Bank"},
                {value:"SHRI_SANT_NAGEBABA_MULTISTATE_COOP_URBA_CREDIT_SOCIETY_LTD",label: "Shri Sant Nagebaba Multistate Coop Urban Credit Society Ltd"},
                {value:"SRI_GURU_RAGHAVENDRA_PATTINA_SOUHARDA_SAHAKARI_NIYAMITHA",label: "Sri Guru Raghavendra Pattina Souharda Sahakari Niyamitha"},
                {value:"MALVIYA_URBAN_CO_OP_BANK_LTD",label: "Malviya Urban Co-op Bank Ltd"},
                {value:"M_D_PAWAR_PEOPLES_CO_OP_BANK",label: "M D Pawar Peoples Co-op Bank"},
                {value:"LALA_URBAN_CO_OP_BANK",label: "Lala Urban Co-op Bank"},
                {value:"KHAMMAM_DCC_BANK",label: "Khammam DCC Bank"},
                {value:"KRISHNA_BHIMA_SAMRUDDHI_BANK",label: "Krishna Bhima Samruddhi Bank"},
                {value:"PUNE_DISTRICT_CENTRAL_CO_OP_BANK",label: "Pune District Central Co-op Bank"},
                {value:"PUNE_CANTONMENT_SAHAKARI_BANK",label: "Pune Cantonment Sahakari Bank"},
                {value:"RAJGURUNAGAR_SAHAKARI_BANK_LTD",label: "Rajgurunagar Sahakari Bank Ltd"},
                {value:"SHIVALIK_SMALL_FINANCE_BANK",label: "Shivalik Small Finance Bank"},
                {value:"BNP_PARIBAS",label: "BNP Paribas"},
                {value:"TELANGANA_STATE_CO_OPERATIVE_APEX_BANK_LTD",label: "Telangana State Cooperative Apex Bank Ltd"},
                {value:"SAHEBRAO_DESHMUKH_CO_OPERATIVE_BANK",label: "Sahebrao Deshmukh Co-operative Bank"},
                {value:"VYAPARI_SAHKARI_BANK",label: "Vyapari Sahkari Bank"},
                {value:"SHRI_PANCHGANGA_NAGARI_SAHAKARI_BANK",label: "Shri Panchganga Nagari Sahakari Bank"},
                {value:"RAIGAD_SAHAKARI_BANK",label: "Raigad Sahakari Bank"},
                {value:"THE_VERAVAL_MERCANTILE_CO_OP_BANK_LTD",label: "The Veraval Mercantile Co-op Bank Ltd"},
                {value:"THE_PIMPALGAON_MERCHANTS_CO_OP_BANK",label: "The Pimpalgaon Merchants Co-op Bank"},
                {value:"THE_TASGAON_URBAN_CO_OP_BANK",label: "The Tasgaon Urban Co-op Bank"},
                {value:"SREE_NARAYANA_GURU_CO_OP_BANK",label: "Sree Narayana Guru Co-op Bank"},
                {value:"SAMPADA_SAHAKARI_BANK",label: "Sampada Sahakari Bank"},
                {value:"BUSINESS_CO_OP_BANK",label: "Business Co-op Bank"},
                {value:"SHRI_SWAMI_SAMARTH_SAHAKARI_BANK",label: "Shri Swami Samarth Sahakari Bank"},
                {value:"RAJASTHAN_URBAN_CO_OP_BANK",label: "Rajasthan Urban Co-op Bank"},
                {value:"KOLHAPUR_DISTRICT_CENTRAL_CO_OP_BANK",label: "Kolhapur District Central Co-op Bank"},
                {value:"LONAVALA_SAHAKARI_BANK",label: "Lonavala Sahakari Bank"},
                {value:"BARCLAYS_BANK",label: "Barclays Bank"},
                {value:"WOORI_BANK",label: "Woori Bank"},
                {value:"SRI_AMBABHAVANI_URBAN_CO_OP_BANK",label: "Sri Ambabhavani Urban Co-op Bank"},
                {value:"SREE_CHARAN_SOUHARDHA_CO_OP_BANK",label: "Sree Charan Souhardha Co-op Bank"},
                {value:"SHRI_SANT_DNYANESHWAR_MULTI_STATE_CO_OP_CREDIT_SOCIETY",label: "Shri Sant Dnyaneshwar Multi-State Co-op Credit Society"},
                {value:"TJSB_SAHAKARI_BANK_LTD",label: "TJSB Sahakari Bank Ltd"},
                {value:"TUMKUR_VEERASHAIVA_CO_OP_BANK",label: "Tumkur Veerashaiva Co-op Bank"},
                {value:"THE_JAMNAGAR_PEOPLES_CO_OP_BANK",label: "The Jamnagar Peoples Co-op Bank"},
                {value:"THE_BELLARY_DISTRICT_CO_OP_CENTRAL_BANK_LTD",label: "The Bellary District Co-op Central Bank Ltd"},
                {value:"THE_RAICHUR_DISTRICT_CENTRAL_CO_OP_BANK",label: "The Raichur District Central Co-op Bank"},
                {value:"BHADRADRI_BANK_LTD",label: "BhadraDri Bank Ltd"},
                {value:"PALUS_SAHAKARI_BANK_LTD",label: "Palus Sahakari Bank Ltd"},
                {value:"PANVEL_CO_OP_URBAN_BANK",label: "Panvel Co-op Urban Bank"},
                {value:"ADITYA_BIRLA_CAPITAL",label: "Aditya Birla Capital"},
                {value:"ASHOK_NAGARI_SAHAKARI_BANK",label: "Ashok Nagari Sahakari Bank"},
                {value:"JIVAN_COMMERCIAL_CO_OP_BANK",label: "Jivan Commercial Co-op Bank"},
                {value:"INDRAYANI_CO_OP_BANK",label: "Indrayani Co-op Bank"},
                {value:"SOLAPUR_SIDDESHWAR_SAHAKARI_BANK",label: "Solapur Siddeshwar Sahakari Bank"},
                {value:"JIVAJI_SAHAKARI_BANK",label: "Jivaji Sahakari Bank"},
                {value:"VISHWAS_CO_OPERATIVE_BANK",label: "Vishwas Co-operative Bank"},
                {value:"APPASAHEB_BIRNALE_SAHAKARI_BANK",label: "Appasaheb Birnale Sahakari Bank"},
                {value:"THE_MODASA_NAGARIK_SAHAKARI_BANK_LTD",label: "The Modasa Nagarik Sahakari Bank Ltd"},
                {value:"THE_AGRASEN_CO_OP_URBAN_BANK_LTD",label: "The Agrasen Co-op Urban Bank Ltd"},
                {value:"THE_MYSORE_MERCHANTS_CO_OP_BANK",label: "The Mysore Merchants Co-op Bank"},
                {value:"RATANCHAND_SHAH_SAHAKARI_BANK",label: "Ratanchand Shah Sahakari Bank"},
                {value:"SOLAPUR_DISTRICT_CENTRAL_CO_OP_BANK",label: "Solapur District Central Co-op Bank"},
                {value:"ADV_SHAMRAOJI_SHINDE_SATYASHODHAK_SAHAKARI_BANK_LTD",label: "Adv. Shamraoji Shinde Satyashodhak Sahakari Bank Ltd"},
                {value:"VALSAD_DIS_CENT_CO_OP_BANK_LTD",label: "Valsad District Central Co-op Bank Ltd"},
                {value:"SHRI_YASHWANT_SAHAKARI_BANK",label: "Shri Yashwant Sahakari Bank"},
                {value:"SHRI_SHANTAPPANNA_MIRJI_URBAN_CO_OP_BANK",label: "Shri Shantappanna Mirji Urban Co-op Bank"},
                {value:"OPEN_BANK",label: "Open Bank"},
                {value:"JALGAON_JANATA_SAHAKARI_BANK",label: "Jalgaon Janata Sahakari Bank"},
                {value:"GAUTAM_SAHAKARI_BANK",label: "Gautam Sahakari Bank"},
                {value:"INDORE_PARASPAR_SAHAKARI_BANK",label: "Indore Paraspar Sahakari Bank"},
                {value:"JANKALYAN_CO_OP_BANK",label: "Jankalyan Co-op Bank"},
                {value:"HUTATMA_SAHAKARI_BANK",label: "Hutatma Sahakari Bank"},
                {value:"LOKVIKAS_NAGARI_SAHAKARI_BANK",label: "Lokvikas Nagari Sahakari Bank"},
                {value:"AHMEDNAGAR_SHAHAR_SAHAKARI_BANK_LTD",label: "Ahmednagar Shahar Sahakari Bank Ltd"},
                {value:"KOLHAPUR_MAHILA_SAHAKARI_BANK_LTD",label: "Kolhapur Mahila Sahakari Bank Ltd"},
                {value:"THE_PANDHARPUR_URBAN_CO_OP_BANK_LTD",label: "The Pandharpur Urban Co-op Bank Ltd"},
                {value:"THE_JAMNAGAR_MAHILA_BANK",label: "The Jamnagar Mahila Bank"},
                {value:"THE_HARIJ_NAGARIK_SAHAKARI_BANK",label: "The Harij Nagarik Sahakari Bank"},
                {value:"THE_SANGAMNER_MERCHANTS_CO_OP_BANK_LTD",label: "The Sangamner Merchants Co-op Bank Ltd"},
                {value:"THE_SANKHEDA_NAGARIK_SAHKARI_BANK",label: "The Sankheda Nagarik Sahkari Bank"},
                {value:"THE_NANDED_MERCHANTS_CO_OP_BANK",label: "The Nanded Merchants Co-op Bank"},
                {value:"PRIME_CO_OP_BANK",label: "Prime Co-op Bank"},
                {value:"DR_ANNASAHEB_CHOUGULE_URBAN_CO_OP_BANK",label: "Dr. Annasaheb Chougule Urban Co-op Bank"},
                {value:"OMPRAKASH_DEORA_PEOPLES_CO_OPERATIVE_BANK",label: "Omprakash Deora Peoples Co-operative Bank"},
                {value:"THE_KAKATIYA_CO_OP_URBAN_BANK_LTD",label: "The Kakatiya Co-op Urban Bank Ltd"},
                {value:"DISTRICT_CO_OP_CENTRAL_BANK",label: "District Co-op Central Bank"},
                {value:"THE_MOTI_URBAN_CO_OP_BANK_LTD",label: "The Moti Urban Co-op Bank Ltd"},
                {value:"THE_GOKAK_URBAN_CO_OP_CREDIT_BANK",label: "The Gokak Urban Co-op Credit Bank"},
                {value:"PRAGATI_CREDIT_SOUHARD_SAHAKARI_NIYAMIT",label: "Pragati Credit Souhard Sahakari Niyamit"},
                {value:"POHEGAON_NAGARI_SAHAKARI_PATSANSTHA_MARYADIT",label: "Pohegaon Nagari Sahakari Patsanstha Maryadit"},
                {value:"RAMRAJYA_SAH_BANK_LTD",label: "Ramrajya Sah Bank Ltd"},
                {value:"THE_TAMIL_NADU_INDUSTRIAL_CO_OP_BANK",label: "The Tamil Nadu Industrial Co-op Bank"},
                {value:"SHRIPATRAODADA_SAHAKARI_BANK",label: "Shripatraodada Sahakari Bank"},
                {value:"SHREE_BASAVESHWAR_URBAN_CO_OP_BANK",label: "Shree Basaveshwar Urban Co-op Bank"},
                {value:"MADHYA_PARADESH_GRAMIN_BANK",label: "Madhya Pradesh Gramin Bank"},
                {value:"SIDDHESHWAR_URBAN_CO_OP_BANK",label: "Siddheshwar Urban Co-op Bank"},
                {value:"THE_VAIDYANATH_URBAN_CO_OP_BANK",label: "The Vaidyanath Urban Co-op Bank"},
                {value:"MALEGAON_MERCHANT_CO_OP_BANK_LTD",label: "Malegaon Merchant Co-op Bank Ltd"},
                {value:"MYSORE_DCC_BANK",label: "Mysore DCC Bank"},
                {value:"MHAVELI_SAHAKARI_BANK_MARYADIT",label: "Mhaveli Sahakari Bank Maryadit"},
                {value:"BHARATI_SAHAKARI_BANK",label: "Bharati Sahakari Bank"},
                {value:"ANDHRA_PRADESH_MAHESH_CO_OP_URBAN_BANK",label: "Andhra Pradesh Mahesh Co-op Urban Bank"},
                {value:"SULAIMANI_CO_OP_BANK",label: "Sulaimani Co-op Bank"},
                {value:"SHRI_GAJANAN_MAHARAJ_URBAN_CO_OP_BANK",label: "Shri Gajanan Maharaj Urban Co-op Bank"},
                {value:"THE_SURAT_MERCANTILE_CO_OP_BANK",label: "The Surat Mercantile Co-op Bank"},
                {value:"SRI_VASAVAMBA_CO_OP_BANK",label: "Sri Vasavamba Co-op Bank"},
                {value:"SRI_SUDHA_CO_OP_BANK",label: "Sri Sudha Co-op Bank"},
                {value:"THE_GODHRA_URBAN_CO_OP_BANK_LTD",label: "The Godhra Urban Co-op Bank Ltd"},
                {value:"THE_PIONEER_URBAN_CO_OP_BANK",label: "The Pioneer Urban Co-op Bank"},
                {value:"THE_UTTARSANDA_PEOPLE_CO_OP_BANK",label: "The Uttarsanda People Co-op Bank"},
                {value:"THE_BABASAHEB_DESHMUKH_SAHAKARI_BANK",label: "The Babasaheb Deshmukh Sahakari Bank"},
                {value:"MANSING_CO_OP_BANK",label: "Mansing Co-op Bank"},
                {value:"MAKARPURA_IND_ESTATE_CO_OP_BANK",label: "Makarpura Ind Estate Co-op Bank"},
                {value:"HIMATNAGAR_NAGRIK_SAHAKARI_BANK",label: "Himatnagar Nagrik Sahakari Bank"},
                {value:"APANI_SAHAKARI_BANK",label: "Apani Sahakari Bank"},
                {value:"THE_ANNASAHEB_SAVANT_CO_OP_URBAN_BANK",label: "The Annasaheb Savant Co-op Urban Bank"},
                {value:"SHREE_MAHALAXMI_URBAN_CO_OP_CREDIT_BANK",label: "Shree Mahalaxmi Urban Co-op Credit Bank"},
                {value:"SHRI_GANESH_SAHAKARI_BANK_LTD",label: "Shri Ganesh Sahakari Bank Ltd"},
                {value:"SIRSI_URBAN_SAHAKARI_BANK",label: "Sirsi Urban Sahakari Bank"},
                {value:"BHARATPE_BANK",label: "BharatPe Bank"},
                {value:"BORGAON_URBAN_CO_OP_CREDIT_SOC_LTD",label: "Borgaon Urban Co-op Credit Soc Ltd"},
                {value:"BHAGINI_NIVEDITA_SAHAKARI_BANK",label: "Bhagini Nivedita Sahakari Bank"},
                {value:"THE_MANDVI_NAGARIK_SAHAKARI_BANK_LTD",label: "The Mandvi Nagarik Sahakari Bank Ltd"},
                {value:"THE_BARODA_CITY_CO_OP_BANK",label: "The Baroda City Co-op Bank"},
                {value:"THE_TIRUCHIRAPALLI_DCC_BANK",label: "The Tiruchirappalli DCC Bank"},
                {value:"THE_BANGALORE_CITY_CO_OP_BANK",label: "The Bangalore City Co-op Bank"},
                {value:"THE_MAHAVEER_CO_OP_BANK",label: "The Mahaveer Co-op Bank"},
                {value:"THE_RAJAJI_NAGAR_CO_OP_BANK",label: "The Rajaji Nagar Co-op Bank"},
                {value:"THE_SATARA_DISTRICT_CENTRAL_CO_OP_BANK",label: "The Satara District Central Co-op Bank"},
                {value:"THE_VIJAY_COMMERCIAL_CO_OP_BANK",label: "The Vijay Commercial Co-op Bank"},
                {value:"OSMANABAD_JANATA_SAHAKARI_BANK",label: "Osmanabad Janata Sahakari Bank"},
                {value:"KAKINADA_CO_OP_CENTRAL_BANK",label: "Kakinada Co-op Central Bank"},
                {value:"DHANSHRI_MULTISTATE_CO_OP_CREDIT_SOCIETY",label: "Dhanshri Multistate Co-op Credit Society"},
                {value:"THE_JAMNAGAR_DISTRICT_CO_OP_BANK",label: "The Jamnagar District Co-op Bank"},
                {value:"THE_RAIGAD_DCC_BANK",label: "The Raigad DCC Bank"},
                {value:"THE_SAURASHTRA_CO_OP_BANK",label: "The Saurashtra Co-op Bank"},
                {value:"THE_GANDHI_GUNJ_CO_OP_BANK",label: "The Gandhi Gunj Co-op Bank"},
                {value:"THE_PANCHMAHAL_DISTRICT_CO_OP_BANK",label: "The Panchmahal District Co-op Bank"},
                {value:"JAWAHAR_SAHAKARI_BANK",label: "Jawahar Sahakari Bank"},
                {value:"THE_BELGAUM_INDUSTRIAL_CO_OP_BANK",label: "The Belgaum Industrial Co-op Bank"},
                {value:"SRI_BASAVESHWAR_SAHAKARI_BANK_NIYAMITHA",label: "Sri Basaveshwar Sahakari Bank Niyamitha"}
                // Add more options if necessary
              ]}
              isSearchable
              styles={customSelectStyles}
            />
          </div>
        )}
        {files.length > 0 && anyLockedFiles && (
          <div className="modal-section">
            <p>
              {Object.values(lockedFiles).filter((status) => status === 'locked').length} out of {files.length} file(s) are password protected. Please enter the password(s).
            </p>
            {files.length > 1 && (
              <label>
                <input
                  type="checkbox"
                  checked={samePasswordForAll}
                  onChange={() => setSamePasswordForAll(!samePasswordForAll)}
                />
                All files have the same password
              </label>
            )}
            {samePasswordForAll && (
              <div className="password-container">
                <div className="password-input-wrapper">
                  <input
                    type="text"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                    className="password-input"
                  />
                </div>
                <button onClick={handleUnlockAll} className="cta-unlock-button">
                  <AiOutlineArrowRight />
                </button>
              </div>
            )}
          </div>
        )}
        <div className="file-upload-container">
          <button
            type="button"
            className="choose-file-button"
            onClick={() => fileInputRef.current.click()}
            disabled={dataType?.value === 'bank' && !bankType}
          >
            Choose File
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple
            style={{ display: 'none' }}
          />
        </div>
        <div className="file-names-container">
          <div className="file-names">
            {files.map((file, index) => (
              <div key={index} className="file-item" title={file.name}>
                <span className="file-name" title={file.name}>
                  {truncateFileName(file.name)}
                </span>
                {lockedFiles[file.name] !== 'noPassword' && lockedFiles[file.name] !== undefined && (
                  <span>
                    {lockedFiles[file.name] === 'locked' ? (
                      <AiFillLock className="file-icon" style={{ color: 'red' }} />
                    ) : (
                      <AiFillUnlock className="file-icon" style={{ color: 'green' }} />
                    )}
                  </span>
                )}
                <div className="file-actions">
                  {lockedFiles[file.name] === 'locked' && !samePasswordForAll && (
                    <div className="password-input-wrapper">
                      <input
                        type="text"
                        value={individualPasswords[file.name] || ''}
                        onChange={(e) =>
                          handlePasswordChange(file.name, e.target.value)
                        }
                        placeholder="Enter password"
                        className="password-input"
                      />
                      <button onClick={() => handleUnlockIndividual(file.name)} className="cta-unlock-button">
                        <AiOutlineArrowRight />
                      </button>
                    </div>
                  )}
                  <button className="file-delete-button" onClick={() => handleFileDelete(file.name)}>
                    <AiOutlineClose />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        {passwordError && <div className="error-message">{passwordError}</div>}
        <div className="modal-actions">
          <button
            className="add-button"
            onClick={handleSubmit}
            disabled={!files.length || !dataType || (dataType.value === 'bank' && !bankType) || passwordError}
          >
            Add
          </button>
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;
