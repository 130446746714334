import React, { useState, useEffect, useRef } from 'react';
import { FaTimes, FaChartBar, FaFilter, FaSortUp, FaSortDown, FaTimesCircle } from 'react-icons/fa';
import SkeletonLoader from '../../loaders/skeltons/SkeletonLoader';
import { getCategorizedBankTransactions } from '../../../api/apiServices';
import ZeroStateComponent from '../../ZeroStateComponent/ZeroStateComponent';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import styles from './BankingTransactions.module.css';
import Loader from '../../Loader/Loader';


const BankingTransactions = ({ customer_uuid, party_category, is_modal_view }) => {
  const [bankingtransactionData, setBankingtransactionData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage, setTransactionsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filters, setFilters] = useState({
    category: [],
    final_party_name: [],
    final_party_category: party_category ? [party_category] : [],
    final_party_classification: [],
    bank: [],
    account_no: [],
    cr_db_indicator: [],
    transaction_date: '',
    amount: '',
    balance: '',
  });

  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });

  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState({
    bank: false,
    account_no: false,
    cr_db_indicator: false,
    final_party_name: false,
    final_party_category: false,
    final_party_classification: false,
  });

  const dropdownRefs = useRef({});

  useEffect(() => {
    const extractData = async () => {
      try {
        setIsLoading(true);
        const response = await getCategorizedBankTransactions(customer_uuid);
        const fetchedData = response.data;

        if (fetchedData && fetchedData.length > 0) {
          setBankingtransactionData(fetchedData);
          setOriginalData(fetchedData);
          setDisplayData(fetchedData);
        } else {
          console.error('Categorized Transactions data is not available');
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching Categorized Transactions data:', error);
        setIsLoading(false);
      }
    };

    extractData();
  }, [customer_uuid]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(dropdownRefs.current).forEach((key) => {
        if (dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)) {
          setIsFilterDropdownOpen((prev) => ({
            ...prev,
            [key]: false,
          }));
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let filteredData = bankingtransactionData;

    if (searchTerm.length >= 3) {
      filteredData = filteredData.filter((item) =>
        item.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    Object.keys(filters).forEach((key) => {
      if (key === 'transaction_date' && filters[key]) {
        filteredData = filteredData.filter((item) =>
          item.transaction_date.startsWith(filters[key])
        );
      } else if (key === 'amount' || key === 'balance') {
        if (filters[key]) {
          const value = parseFloat(filters[key]);
          filteredData = filteredData.filter((item) =>
            item[key] === value
          );
        }
      } else if (filters[key].length > 0) {
        filteredData = filteredData.filter((item) =>
          filters[key].includes(item[key])
        );
      }
    });

    if (sortConfig.key) {
      filteredData = filteredData.sort((a, b) => {
        const aValue = parseFloat(a[sortConfig.key]);
        const bValue = parseFloat(b[sortConfig.key]);
        if (sortConfig.direction === 'ascending') {
          return aValue - bValue;
        } else if (sortConfig.direction === 'descending') {
          return bValue - aValue;
        }
        return 0;
      });
    }

    setDisplayData(filteredData);
  }, [searchTerm, filters, bankingtransactionData, sortConfig]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleTransactionsPerPageChange = (event) => {
    setTransactionsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (key, selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: selectedOptions ? selectedOptions.map(option => option.value) : [],
    }));
  };

  const handleDateChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      transaction_date: event.target.value,
    }));
  };

  const clearDateFilter = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      transaction_date: '',
    }));
  };

  const handleAmountBalanceFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const toggleFilterDropdown = (key) => {
    setIsFilterDropdownOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const toggleSort = (key) => {
    setSortConfig((prevSortConfig) => {
      if (prevSortConfig.key === key) {
        if (prevSortConfig.direction === 'ascending') {
          return { key, direction: 'descending' };
        } else if (prevSortConfig.direction === 'descending') {
          return { key: '', direction: 'ascending' };
        }
      }
      return { key, direction: 'ascending' };
    });
  };

  const resetSort = () => {
    setSortConfig({ key: '', direction: 'ascending' });
    setDisplayData(originalData);
  };

  const formatValue = (value) => parseFloat(value).toLocaleString('en-IN', { style: 'currency', currency: 'INR' });

  const startIndex = (currentPage - 1) * transactionsPerPage;
  const currentTransactions = displayData.slice(startIndex, startIndex + transactionsPerPage);
  const totalPages = Math.ceil(displayData.length / transactionsPerPage);

  const createFilterOptions = (key) => {
    return [...new Set(bankingtransactionData.map(item => item[key]))].map(value => ({
      value,
      label: value,
    }));
  };

  const renderFilterDropdown = (key) => {
    const options = createFilterOptions(key);
    const selectedValues = filters[key];

    return (
      <div className={styles.filterDropdown} ref={(el) => (dropdownRefs.current[key] = el)}>
        {options.map(option => (
          <label key={option.value} className={styles.checkboxLabel}>
            <input
              type="checkbox"
              value={option.value}
              checked={selectedValues.includes(option.value)}
              onChange={(e) => {
                const newSelectedValues = e.target.checked
                  ? [...selectedValues, option.value]
                  : selectedValues.filter(value => value !== option.value);
                handleFilterChange(key, newSelectedValues.map(val => ({ value: val })));
              }}
            />
            {option.label}
          </label>
        ))}
      </div>
    );
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return <FaSortUp className={styles.sortIcon} />;
    if (sortConfig.direction === 'ascending') return (
      <>
        <FaSortUp className={styles.sortIconActive} />
        
      </>
    );
    if (sortConfig.direction === 'descending') return (
      <>
        <FaSortDown className={styles.sortIconActive} />
        
      </>
    );
    
  };

  const tableHeaderStyle = {
    bank: { width: '100px' },
    accountNo: { width: '180px' },
    transactionDate: { width: '150px' },
    description: { width: '500px' },
    amount: { width: '180px' },
    crDb: { width: '80px' },
    balance: { width: '180px' },
    partyName: { width: '230px' },
    category: { width: '200px' },
    classification: { width: '100px' }
  };

  const renderTable = (data) => {
    return (
      <div className={styles.tableSection}>
        {sortConfig.key && (
          <button onClick={resetSort} className={styles.resetButton}>
            Reset Sort
          </button>
        )}
      <div className={styles.tableWrapper}>
        <table className={styles.bankingDataTable}>
          <thead>
            <tr>
              <th style={tableHeaderStyle.bank}>Bank</th>
              <th style={tableHeaderStyle.accountNo}>Account No</th>
              <th style={tableHeaderStyle.transactionDate}>Date</th>
              <th style={tableHeaderStyle.description}>Description</th>
              <th style={tableHeaderStyle.amount}>
                Amount
                
              </th>
              <th style={tableHeaderStyle.crDb}>CR/DB</th>
              <th style={tableHeaderStyle.balance}>
                Balance
                
              </th>
              <th style={tableHeaderStyle.partyName}>Party Name</th>
              <th style={tableHeaderStyle.category}>Category</th>
              <th style={tableHeaderStyle.classification}>Classification</th>
            </tr>
            <tr className={styles.filtersRow}>
              <th>
                <FaFilter className={styles.filterIcon} onClick={() => toggleFilterDropdown('bank')} />
                {isFilterDropdownOpen.bank && renderFilterDropdown('bank')}
              </th>
              <th>
                <FaFilter className={styles.filterIcon} onClick={() => toggleFilterDropdown('account_no')} />
                {isFilterDropdownOpen.account_no && renderFilterDropdown('account_no')}
              </th>
              <th>
                <div className={styles.dateFilter}>
                  <input
                    type="date"
                    onChange={handleDateChange}
                    className={styles.dateInput}
                    value={filters.transaction_date}
                    placeholder="yyyy/mm/dd"
                  />
                  {filters.transaction_date && (
                    <FaTimes className={styles.clearIcon} onClick={clearDateFilter} />
                  )}
                </div>
              </th>
              <th>
                <input
                  type="text"
                  placeholder="Search Description"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className={styles.searchInput}
                />
              </th>
              <th>
                  <input
                    type="number"
                    placeholder="Amount"
                    onChange={(e) => handleAmountBalanceFilterChange('amount', e.target.value)}
                    value={filters.amount}
                    className={styles.amountInput}
                  />
                  <span className="sort-icon" onClick={() => toggleSort('amount')}>
                    {getSortIcon('amount')}
                  </span>
              </th>
              <th>
                <FaFilter className={styles.filterIcon} onClick={() => toggleFilterDropdown('cr_db_indicator')} />
                {isFilterDropdownOpen.cr_db_indicator && renderFilterDropdown('cr_db_indicator')}
              </th>
              <th className={styles.filterSortContainer}>
                <input
                  type="number"
                  placeholder="Balance"
                  onChange={(e) => handleAmountBalanceFilterChange('balance', e.target.value)}
                  value={filters.balance}
                  className={styles.balanceInput}
                />
                <span onClick={() => toggleSort('balance')}>
                  {getSortIcon('balance')}
                </span>
              </th>
              <th>
                <FaFilter className={styles.filterIcon} onClick={() => toggleFilterDropdown('final_party_name')} />
                {isFilterDropdownOpen.final_party_name && renderFilterDropdown('final_party_name')}
              </th>
              <th>
                <FaFilter className={styles.filterIcon} onClick={() => toggleFilterDropdown('final_party_category')} />
                {isFilterDropdownOpen.final_party_category && renderFilterDropdown('final_party_category')}
              </th>
              <th>
                <FaFilter className={styles.filterIcon} onClick={() => toggleFilterDropdown('final_party_classification')} />
                {isFilterDropdownOpen.final_party_classification && renderFilterDropdown('final_party_classification')}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan="10">
                  <ZeroStateComponent />
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index} className={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                  <td>{item.bank}</td>
                  <td>{item.account_no}</td>
                  {/* <td>{item.transaction_date}</td> */}
                  <td>{new Date(item.transaction_date).toLocaleDateString('en-GB')}</td>
                  <td className={styles.descriptionCell}>{item.description}</td>
                  <td>{formatValue(item.amount)}</td>
                  <td>{item.cr_db_indicator}</td>
                  <td>{formatValue(item.balance)}</td>
                  <td>{item.final_party_name}</td>
                  <td>{item.final_party_category}</td>
                  <td>{item.final_party_classification}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        </div>

        <div 
          className={styles.pagination} 
          style={!is_modal_view ? { marginRight: "60px" } : {}}
        >
          <label>
            Show
            <select value={transactionsPerPage} onChange={handleTransactionsPerPageChange}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
            </select>
            items per page
          </label>
          <div className={styles.pageControls}>
            {currentPage > 1 && (
              <button onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
            )}
            {currentPage > 3 && <span>...</span>}
            {Array.from({ length: Math.min(3, totalPages) }, (_, i) => {
              const pageNumber = i + Math.max(1, currentPage - 1);
              return (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  disabled={pageNumber === currentPage}
                >
                  {pageNumber}
                </button>
              );
            })}
            {currentPage < totalPages - 2 && <span>...</span>}
            {currentPage < totalPages && (
              <button onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const downloadExcel = () => {
    if (bankingtransactionData.length === 0) return;
  
    // List of keys to be removed
    const keysToRemove = ['customer_uuid', 'transaction_id', 'execution_uuid','uuid']; // Replace with the keys you want to remove
  
    // Function to remove specified keys from an object
    const removeKeys = (obj, keys) => {
      const newObj = { ...obj };
      keys.forEach(key => {
        delete newObj[key];
      });
      return newObj;
    };
  
    // Create a new array with specified keys removed
    const filteredData = bankingtransactionData.map(transaction => removeKeys(transaction, keysToRemove));
  
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');
  
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'Categorized_Transactions.xlsx');
  };
  

  return (
    <div>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          {/* <SkeletonLoader /> */}
          <Loader LoaderContent = "Fetching your transactions" />
          {/* <div className=''>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" style={{ animation: 'rotate 2s linear infinite', width: '70px', height: '70px' }}>
            <path fill="#4CAF50" stroke="#4CAF50" stroke-width="15" transform-origin="center" d="m148 84.7 13.8-8-10-17.3-13.8 8a50 50 0 0 0-27.4-15.9v-16h-20v16A50 50 0 0 0 63 67.4l-13.8-8-10 17.3 13.8 8a50 50 0 0 0 0 31.7l-13.8 8 10 17.3 13.8-8a50 50 0 0 0 27.5 15.9v16h20v-16a50 50 0 0 0 27.4-15.9l13.8 8 10-17.3-13.8-8a50 50 0 0 0 0-31.7Zm-47.5 50.8a35 35 0 1 1 0-70 35 35 0 0 1 0 70Z">
              <animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="0;120" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite">
              </animateTransform></path>
          </svg>
          <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Fetching your details</p>
          </div> */}

        </div>
      ) : (
        <>
          {!modalIsOpen && (
            <div className={styles.headerContainer}>
              {is_modal_view ? (
                <h2 className={styles.title}></h2>
              ) : (
                <h2 className={styles.title}>
                  <FaChartBar className={styles.icon} />
                  Categorized Bank Transactions
                </h2>
              )}
              
              {bankingtransactionData.length > 0 && (
                <button onClick={downloadExcel} className={styles.downloadButton}>
                  Download All Transactions
                </button>
              )}
            </div>
          )}
          {/* <div className={styles.tableWrapper}> */}
            {renderTable(currentTransactions)}
          {/* </div> */}
        </>
      )}
    </div>
  );
};

export default BankingTransactions;
