import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import HomePage from "./components/HomePage/HomePage";
import Dashboard from "./components/Dashboard/Dashboard";
import LoginModal from "./components/LoginModal/LoginModal";
import GetStarted from "./components/GetStarted/GetStarted"; // Import the new component

// Utility function to decode a JWT token
const decodeToken = (token) => {
  try {
    const base64Url = token.split('.')[1]; // Get the payload part of the JWT
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload); // Return the decoded payload as an object
  } catch (error) {
    console.error("Failed to decode token:", error);
    return null;
  }
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    
    if (token) {
      const decodedToken = decodeToken(token);
      
      if (decodedToken && decodedToken.exp) {
        const currentTime = Math.floor(Date.now() / 1000); // Current UTC time in seconds
        const bufferTime = 18.5 * 60 * 60; // 18.5 hours in seconds (66600 seconds)
        
        // Check if the token will expire within 18.5 hours from now
        if (decodedToken.exp > currentTime && (decodedToken.exp - currentTime) > bufferTime) {
          setIsAuthenticated(true); // Token is valid and safe from expiration for 18.5 hours
        } else {
          console.log("Token has expired or is too close to expiration");
          setIsAuthenticated(false);
          localStorage.removeItem("accessToken"); // Optionally remove expired or soon-to-expire token
        }
      } else {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []); // Empty dependency array to run only on mount

  return (
    <Router>
      <Routes>
        {/* Define specific routes for login and get-started */}
        <Route
          path="/login"
          element={<LoginModal setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route path="/get-started" element={<GetStarted />} />

        {/* The authenticated routes */}
        <Route
          path="/"
          element={
            <Layout
              isAuthenticated={isAuthenticated}
              setIsAuthenticated={setIsAuthenticated}
            />
          }
        >
          <Route index element={<HomePage />} />
          <Route
            path="dashboard/:customer_uuid"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
