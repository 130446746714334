import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './DynamicTable.module.css';
import './DynamicTable.css';
import { FaChartBar } from 'react-icons/fa';
import ZeroStateComponent from '../../ZeroStateComponent/ZeroStateComponent';

const cleanAndFormatTitle = (title) => {
  const cleanedTitle = title.replace(/[_\W]+/g, ' ');
  const formattedTitle = cleanedTitle.replace(/[^a-zA-Z0-9]/g, ' ');
  return formattedTitle;
};

const DynamicTable = ({ data, fixedColumns, tableTitle, scrollableHeaders, includeTotalColumns }) => {
  const fixedTableRef = useRef(null);
  const scrollableTableRef = useRef(null);

  useEffect(() => {
    const syncScroll = (source, target) => {
      if (source && target) {
        target.scrollTop = source.scrollTop;
      }
    };

    const fixedTable = fixedTableRef.current;
    const scrollableTable = scrollableTableRef.current;

    if (fixedTable && scrollableTable) {
      const handleFixedTableScroll = () => syncScroll(fixedTable, scrollableTable);
      const handleScrollableTableScroll = () => syncScroll(scrollableTable, fixedTable);

      fixedTable.addEventListener('scroll', handleFixedTableScroll);
      scrollableTable.addEventListener('scroll', handleScrollableTableScroll);

      return () => {
        fixedTable.removeEventListener('scroll', handleFixedTableScroll);
        scrollableTable.removeEventListener('scroll', handleScrollableTableScroll);
      };
    }
  }, []);

  if (!data || !data.length) {
    return <ZeroStateComponent tableTitle={cleanAndFormatTitle(tableTitle)} />;
  }

  const totalCredits = data.reduce((acc, item) => acc + item.Total, 0);
  const months = scrollableHeaders || Object.keys(data[0]).filter(key => !fixedColumns.map(col => col.key).includes(key));

  const minRows = 5;
  const emptyRows = minRows - data.length > 0 ? minRows - data.length : 0;

  return (
    <div className={styles.tableContainer}>
      <h2 className={styles.title} style={{marginBottom: '10px'}}>
        <FaChartBar className={styles.icon} />
        {tableTitle}
      </h2>
      <div className={styles.tableWrapper}>
        {/* <div style={{maxHeight: '300px !important', overflowY: 'auto'}} ref={fixedTableRef}> */}
        <div className={styles.fixedTableContainer} ref={fixedTableRef}>
          <table className={styles.fixedTable} style={{borderTopWidth: '0px'}}>
            <thead>
              <tr className={styles.headerRow} style={{borderBottomWidth: '0px'}}>
                {fixedColumns.map(column => (
                  <th key={column.key} className={styles.headerCell}>{column.header}</th>
                ))}
                {includeTotalColumns && (
                  <>
                    <th className={styles.headerCell}>Total</th>
                    <th className={styles.headerCell}>% Total</th>
                    {/* <th className={styles.headerCell} style={{ width: '80px' }}>% Total</th> */}
                  </>
                )}
              </tr>
            </thead>
            <tbody className={styles.minHeightTbody}>
              {data.map((row, index) => (
                <tr key={index} className={styles.bodyRow}>
                  {fixedColumns.map(column => (
                    <td key={column.key} className={styles.bodyCell}>
                      {column.render ? column.render(row[column.key]) : row[column.key]}
                    </td>
                  ))}
                  {includeTotalColumns && (
                    <>
                      <td className={styles.bodyCell}>{row.Total.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</td>
                      <td className={styles.bodyCell}>{((row.Total / totalCredits) * 100).toFixed(1)}%</td>
                    </>
                  )}
                </tr>
              ))}
              {Array.from({ length: emptyRows }).map((_, index) => (
                <tr key={`empty-${index}`} className={styles.bodyRow}>
                  {fixedColumns.map(column => (
                    <td key={column.key} className={styles.bodyCell}></td>
                  ))}
                  {includeTotalColumns && (
                    <>
                      <td className={styles.bodyCell}></td>
                      <td className={styles.bodyCell}></td>
                    </>
                  )}
                </tr>
              ))}
              {/* <tr className={styles.emptyRow}></tr> */}
            </tbody>
            {includeTotalColumns && (
              <tfoot>
                <tr className={styles.totalRow}>
                  <td colSpan={fixedColumns.length}></td>
                  <td colSpan={2} className={styles.totalCell}>
                    Total {totalCredits.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
        <div className={styles.scrollableTableContainer} ref={scrollableTableRef}>
          <table className={styles.scrollableTable} style={{borderTopWidth: '0px', maxWidth: '600px'}}>
            <thead>
              <tr className={styles.headerRow} >
                {months.map(month => (
                  <th key={month} className={styles.headerCell} style={{width: months.length <= 4 ? '100%' : '129px'}}>{month}</th>
                ))}
              </tr>
            </thead>
            <tbody className={styles.minHeightTbody}>
              {data.map((row, index) => (
                <tr key={index} className={styles.bodyRow}>
                  {months.map(month => (
                    <td key={month} className={styles.bodyCell}>
                      {(row[month] !== undefined ? row[month] : 0).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                    </td>
                  ))}
                </tr>
              ))}
              {Array.from({ length: emptyRows }).map((_, index) => (
                <tr key={`empty-${index}`} className={styles.bodyRow}>
                  {months.map(month => (
                    <td key={month} className={styles.bodyCell}></td>
                  ))}
                </tr>
              ))}
              {/* <tr className={styles.emptyRow}></tr> */}
            </tbody>
            <tfoot>
              <tr className={styles.totalRow}>
                <td colSpan={months.length}></td>
                <td colSpan={2} className={styles.totalCell}>
                  .
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

DynamicTable.propTypes = {
  data: PropTypes.array.isRequired,
  fixedColumns: PropTypes.array.isRequired,
  tableTitle: PropTypes.string.isRequired,
  scrollableHeaders: PropTypes.array,
  includeTotalColumns: PropTypes.bool
};

DynamicTable.defaultProps = {
  scrollableHeaders: null,
  includeTotalColumns: true
};

export default DynamicTable;