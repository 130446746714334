export const API_BASE_URL = "https://api3.kreditmind.com"; // Example base URL, adjust as necessary

export const API_ROUTES = {
  login: `${API_BASE_URL}/login`,
  upload: `${API_BASE_URL}/upload/`,
  analyze: `${API_BASE_URL}/analyze`,
  taskStatus: `${API_BASE_URL}/analyze/task-status`,
  insight: `${API_BASE_URL}/analyze/insight`,
  analyzeData: `${API_BASE_URL}/analyze/`, // If this is the same as 'analyze', consider combining them
  createCustomer: `${API_BASE_URL}/customer/`, // Assuming this is your endpoint, might need adjustment
  customerList: `${API_BASE_URL}/customer/`, // Adjust according to your actual endpoint
  customerDetails: `${API_BASE_URL}/customer`, // Base URL for customer details, ID will be appended dynamically
  onDemandAnalysis: `${API_BASE_URL}/chat/get_answer`, // Base URL for onDemandAnalysis 
  generateCamReport: `${API_BASE_URL}/analyze/generate-report`,
  categorizedBankTransaction: `${API_BASE_URL}/banking/categorised_transactions`,
  fraudCheck: `${API_BASE_URL}/banking/fraud_check`,
  bankBalanceDetails: `${API_BASE_URL}/banking/abb_details`
};
  