import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, YAxis } from 'recharts';
import { FaChartBar, FaCalendarAlt } from 'react-icons/fa';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import styles from './BankingABBInsights.module.css'; // Ensure the correct path to the CSS file
import './BankingABBInsights.css';
import { getBankBalanceDetails } from '../../../api/apiServices';
import Button from '../../Button/Button';
import ZeroStateComponent from '../../ZeroStateComponent/ZeroStateComponent';

const BankingABBInsights = ({ customer_uuid, insights_data }) => {
  const [tableData, setTableData] = useState([]); // Initial table data
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sliderValue, setSliderValue] = useState(8000);
  const [minValue, setMinValue] = useState(-10000000); // State for dynamic min value
  const [maxValue, setMaxValue] = useState(10000000);  // State for dynamic max value
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isThresholdEnabled, setIsThresholdEnabled] = useState(false);
  const [isMedian, setIsMedian] = useState(false); // New state for toggle
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);

  useEffect(() => {
    if (insights_data && insights_data.data.abb_pivot) {
      setTableData(insights_data.data.abb_pivot);
      const defaultStartDate = getDefaultStartDate(insights_data.data.abb_pivot);
      const defaultEndDate = getDefaultEndDate(insights_data.data.abb_pivot);
      setTempStartDate(defaultStartDate);
      setTempEndDate(defaultEndDate);
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
      fetchGraphData(customer_uuid, defaultStartDate, defaultEndDate);
    } else {
      setTableData([]);
    }
  }, [insights_data]);

  const getDefaultStartDate = (data) => {
    const dates = data.map(d => Object.keys(d)).flat().filter(key => /^\d{4}-\d{2}$/.test(key));
    const minDate = dates.reduce((min, current) => current < min ? current : min, dates[0]);
    const startDate = new Date(`${minDate}-01`);
    const endDate = getDefaultEndDate(data);
    const twelveMonthsAgo = new Date(endDate);
    twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() -12);
    return startDate < twelveMonthsAgo ? twelveMonthsAgo : startDate;
  };

  const getDefaultEndDate = (data) => {
    const dates = data.map(d => Object.keys(d)).flat().filter(key => /^\d{4}-\d{2}$/.test(key));
    const maxDate = dates.reduce((max, current) => current > max ? current : max, dates[0]);
    return new Date(`${maxDate}-01`);
  };

  const formatDateString = (date) => {
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  };

  const fetchGraphData = async (customer_uuid, startDate, endDate) => {
    if (startDate && endDate) {
      setIsLoading(true);
      try {
        const formattedStart = formatDateString(startDate);
        const formattedEnd = formatDateString(endDate);
        const response = await getBankBalanceDetails(customer_uuid, formattedStart, formattedEnd);
        setGraphData(response); // Only update graph data
        setIsThresholdEnabled(true);
        calculateMinMaxValues(response); // Calculate min and max values
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDateChange = async () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    fetchGraphData(customer_uuid, tempStartDate, tempEndDate);
  };

  const calculateMinMaxValues = (data) => {
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;
    data.forEach(entry => {
      Object.keys(entry).forEach(key => {
        if (key !== 'date') {
          const value = entry[key] === null ? 0 : entry[key];
          if (value < min) min = value;
          if (value > max) max = value;
        }
      });
    });
    setMinValue(min);
    setMaxValue(max);
    setSliderValue(min); // Set slider initial value to min
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value === '' ? '' : Number(event.target.value);
    if (newValue >= minValue && newValue <= maxValue) {
      setSliderValue(newValue);
    }
  };

  const formatValue = (value) => parseFloat(value).toFixed(2);

  const countDataPointsBelowLimit = (data, limit) => {
    const counts = {};
    data.forEach(entry => {
      Object.keys(entry).forEach(key => {
        if (key !== 'date' && (entry[key] === null ? 0 : entry[key]) < limit) {
          counts[key] = (counts[key] || 0) + 1;
        }
      });
    });
    return counts;
  };

  const handleAccountClick = (account) => {
    setSelectedAccounts(prevSelectedAccounts => {
      if (prevSelectedAccounts.includes(account)) {
        const newSelectedAccounts = prevSelectedAccounts.filter(acc => acc !== account);
        return newSelectedAccounts;
      } else {
        return [...prevSelectedAccounts, account];
      }
    });
  };

  const handleToggleChange = (event) => {
    setIsMedian(event.target.checked);
  };

  const renderTable = () => {
    if (tableData.length === 0) return <ZeroStateComponent tableTitle=" Bank Balance Analysis"/>;

    const headers = Object.keys(tableData[0]).filter(key => /^\d{4}-\d{2}$/.test(key));
    const filteredData = tableData.filter(data => data.balance_type === (isMedian ? 'Median' : 'Average'));

    return (
      <div className={styles.abbInsightsDataTable}>
        <h2 className={styles.title}>
          <FaChartBar className={styles.icon} />
          Bank Balance Analysis
        </h2>

        <div>
          <div className={styles.toggleContainer}>
            <Typography variant="subtitle1">Average</Typography>
            <Switch checked={isMedian} onChange={handleToggleChange} />
            <Typography variant="subtitle1">Median</Typography>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.bankingDataTable}>
              <thead>
                <tr>
                  <th>Account</th>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((data, idx) => (
                  <tr key={idx}>
                    <td>{data.final_party_name}</td>
                    {headers.map((header, index) => (
                      <td key={index}>{formatValue(data[header] === null ? 0 : data[header])}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderGraph = (data) => {
    if (!data || data.length === 0) {
      return null;
    }

    const colors = ["#8884d8", "#82ca9d"];
    const counts = countDataPointsBelowLimit(data, sliderValue);
    const renderCustomizedDot = (props) => {
      const { cx, cy, value } = props;
      if ((value === null ? 0 : value) < sliderValue) {
        return (
          <circle cx={cx} cy={cy} r={5} fill="red" stroke="none" />
        );
      }
      return (
        <circle cx={cx} cy={cy} r={5} fill="blue" stroke="none" />
      );
    };

    const accountsToDisplay = Object.keys(data[0]).filter(key => key !== 'date');

    return (
      <div className={styles.tableSection}>
        <div className={styles.lineChartSection}>
          <Box className={styles.sliderContainer}>
            <Typography variant="subtitle1" className={styles.sliderLabel}>Set Threshold:</Typography>
            <Slider
              value={sliderValue}
              onChange={handleSliderChange}
              aria-labelledby="y-axis-slider"
              min={minValue} // Use dynamic min value
              max={maxValue} // Use dynamic max value
              step={5000}
              valueLabelDisplay="auto"
              className={styles.slider}
              disabled={!isThresholdEnabled}
            />
            <TextField
              value={sliderValue}
              onChange={handleInputChange}
              inputProps={{
                step: 1000,
                min: minValue, // Use dynamic min value
                max: maxValue, // Use dynamic max value
                type: 'number',
                'aria-labelledby': 'y-axis-slider',
              }}
              className={styles.textField}
              disabled={!isThresholdEnabled}
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontWeight: 400,
                fontSize: '18px',
                letterSpacing: '0.00938em',
                width: '150px' // Increased width of the text box
              }}
            />
          </Box>
          <ResponsiveContainer width="95%" height={400}>
            <LineChart data={data}
              margin={{ top: 20, right: 50, left: 30, bottom: 10 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis domain={[minValue, maxValue]} /> {/* Use dynamic min and max values */}
              <Tooltip />
              <Legend onClick={(e) => handleAccountClick(e.dataKey)} />
              {accountsToDisplay.map((account, index) => (
                <Line
                  key={account}
                  type="monotone"
                  dataKey={account}
                  name={`${account} (${counts[account] || 0})`}
                  stroke={colors[index % colors.length]}
                  fill={colors[index % colors.length]}
                  strokeWidth={selectedAccounts.includes(account) ? 4 : 1}
                  dot={renderCustomizedDot}
                  activeDot={{ r: 8 }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.abbInsightsContainer}>
      <div className={styles.bankingDataContainer}>
        {renderTable()}
        <h2 className={styles.title} style={{ marginTop: '20px' }}>
          <FaChartBar className={styles.icon} />
          Threshold Analysis
        </h2>
        <div className={styles.metricsContainer}>
          <div>
            <Typography variant="subtitle1">From:</Typography>
            <div className={styles.datePickerWrapper}>
              <FaCalendarAlt className={styles.calendarIcon} />
              <DatePicker
                selected={tempStartDate}
                onChange={(date) => setTempStartDate(date)}
                maxDate={new Date()}
                placeholderText="Select start date"
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <div>
            <Typography variant="subtitle1">To:</Typography>
            <div className={styles.datePickerWrapper}>
              <FaCalendarAlt className={styles.calendarIcon} />
              <DatePicker
                selected={tempEndDate}
                onChange={(date) => setTempEndDate(date)}
                maxDate={new Date()}
                placeholderText="Select end date"
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <div style={{ paddingTop: '15px' }}>
            <Button label="Submit" onClick={handleDateChange} />
          </div>
        </div>
        {isLoading && (
          <div className={styles.loaderContainer}>
            <div className={styles.loaderBar}></div>
          </div>
        )}
        {!isLoading && isThresholdEnabled && renderGraph(graphData)}
      </div>
    </div>
  );
};

export default BankingABBInsights;
