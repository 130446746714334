

import React, { useState } from 'react';
import './CustomDropdown.css'; // Make sure you have corresponding CSS for styling

const CustomDropdown = ({ value, onChange, options, title }) => {
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown open/close

  // This function is called when an option is clicked
  const handleOptionClick = (value) => {
    onChange(value); // Notify the parent component about the change
    setIsOpen(false); // Close the dropdown menu
  };

  return (
    <div className="custom-dropdown">
      <label>{title}</label>
      <div className="dropdown-display" onClick={() => setIsOpen(!isOpen)}>
        {options.find(option => option.value === value)?.label || "Select an option"}
      </div>
      {isOpen && (
        <div className="dropdown-options">
          {options.map((option) => (
            <div
              key={option.value}
              className="dropdown-option"
              onClick={() => handleOptionClick(option.value)} // Pass the option's value
            >
{/* <img src={option.logoUrl} alt={option.label} className="option-logo" />  */}
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
