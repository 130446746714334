import React from 'react';
import './AnalysisResponse.css';
import MetricBox from '../metrics/MetricBox';
import ErrorComponent from '../ErrorComponent';
import NotFoundComponent from '../NotFoundComponent';
import RecommendationNotes from './RecommendationNotes'; // Import the new component
const _ = require('lodash');

const AnalysisResponse = ({ analysisData }) => {
  if (!analysisData) {
    return <NotFoundComponent />;
  }

  const {
    status,
    credit_limit,
    risk_score,
    tenure,
    irr,
    monthly_repayment,
    recommendation_notes,
  } = analysisData;

  const formatValue = (value) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
  };

  return (
    <div className="analysis-response-container">
    <div className="analysis-response">
      <div className="analysis-response-header">
        <MetricBox title="Status" value={status} />
        <MetricBox title="Credit Limit" value={credit_limit !== null ? formatValue(credit_limit) : '0.00'} />
        <MetricBox title="Risk Score" value={risk_score} />
        <MetricBox title="Tenure" value={tenure} />
        <MetricBox title="IRR" value={irr} />
        <MetricBox title="Monthly Repayment" value={formatValue(monthly_repayment)} />
      </div>
    </div>
    </div>
  );
};

export default AnalysisResponse;
