import React from 'react';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa'; // Importing FontAwesome icons

// Function to render thumbs up or down based on the metric value
const renderLevelIcon = (title, value) => {
  console.log(`Rendering icon for ${title} with value: ${value}`); // Debugging

  if (title === "Recommendation") {
    if (value === "Approved") {
      return <FaThumbsUp style={{ color: '#4CAF50', fontSize: '20px' }} />;
    } else if (value === "Reject") {
      return <FaThumbsDown style={{ color: '#FF4D4D', fontSize: '20px' }} />;
    }
  }

  if (["Intent Level", "Ability Level", "Compliance Level"].includes(title)) {
    console.log(value)
    if (value === "Good" || value === "Compliant") {
      return <FaThumbsUp style={{ color: '#4CAF50', fontSize: '20px' }} />;
    } else if (value === "Bad" || value === "Not Good" || value === "Not compliant") {
      return <FaThumbsDown style={{ color: '#FF4D4D', fontSize: '20px' }} />;
    }
    // return <FaThumbsUp style={{ color: '#4CAF50', fontSize: '20px' }} />;
  }

  return null;
};

const MetricBox = ({ title, value, trend }) => {
  const titlesWithRupee = ['ABB', 'Monthly cash burn', 'Monthly Loan Repayment', 'Available Cashflow', 'Monthly Repayment', 'Credit Limit',"Recommended Credit Limit"];
  const titlesWithPercentage = ['IRR'];

  const formatValue = (value) => {
    if (typeof value === 'string') {
      value = value.replace(/,/g, ''); // Remove commas from the string
    }
    value = parseFloat(value); // Convert to float
    return isNaN(value) ? '0.00' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
  };

  const formattedValue = () => {
    if (titlesWithRupee.includes(title)) {
      return `₹ ${value !== null ? formatValue(value) : '0.00'}`;
    } else if (titlesWithPercentage.includes(title)) {
      return `${value !== null ? formatValue(value) : '0.00'} %`;
    } else {
      return value === "nan%" ? 'NA' : value !== null  ? value : 'NA';
    }
  };

  const levelIcon = renderLevelIcon(title, value);

  return (
    <div className="metric" style={{ padding: '10px', borderRadius: '8px', position: 'relative' }}>
      <div className="metric-title">{title}</div>
      <div className="metric-value">{formattedValue()}</div>
      <div className="metric-trend">{trend}</div>
      {levelIcon && (
        <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
          {levelIcon}
        </div>
      )}
    </div>
  );
};

export default MetricBox;
