import React, { useState, useEffect } from 'react';
import MetricBox from '../metrics/MetricBox';
import RevenueChart from '../charts/RevenueChart';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, LineChart, Line } from 'recharts';
import { generateCamReport } from '../../api/apiServices';
import RecommendationNotes from '../recommendations/RecommendationNotes';
import './AnalysisSummary.css';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import ZeroStateComponent from '../ZeroStateComponent/ZeroStateComponent';

const AnalysisSummary = ({ customer_uuid, insights_data, analysis_data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [revenueChartData, setRevenueChartData] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [reportUrl, setReportUrl] = useState('');

  useEffect(() => {
    console.log("insights_data", insights_data);
    console.log("analysis_data", analysis_data);
  }, [insights_data, analysis_data]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const create_recommendation_metrics = (analysis_result) => {
    return [
        { Metric: "Recommendation", Value: analysis_result?.data?.status },
        { Metric: "Recommended Credit Limit", Value: analysis_result?.data?.credit_limit || '-' },
        { Metric: "Intent Level", Value: analysis_result?.data?.intent_level || '-'},
        { Metric: "Ability Level", Value: analysis_result?.data?.ability_level || '-'},
        { Metric: "Compliance Level", Value: analysis_result?.data?.compliance_level || '-' },
        
    ];
};

  const renderTable = (data, title, columns) => (
    <div className="table-section">
      <h3>{title}</h3>
      <table>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((col, idx) => (
                <td key={idx}>{col === 'From' || col === 'To' ? formatDate(row[col]) : row[col]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const handleGenerateReport = async () => {
    setIsGenerating(true);
    try {
      const response = await generateCamReport({ customer_uuid });
      if (response?.data?.report_url) {
        setReportUrl(response.data.report_url);
      }
    } catch (error) {
      console.error('Error generating CAM report:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDownloadReport = () => {
    window.open(reportUrl, '_blank');
  };

  const renderBarChart = (data, title) => {
    const chartData = data.map(item => ({
      name: item.Metric,
      value: parseFloat(item.Value.replace(/,/g, '').replace('%', ''))
    }));

    return (
      <div className="chart-section">
        <h3>{title}</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#8884d8">
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.value < 0 ? '#ff4d4d' : '#82ca9d'} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const renderLineChart = (data, title) => (
    <div className="chart-section">
      <h3>{title}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );

  const additionalMetrics = insights_data?.data?.metrics || [];
  const accountInfo = insights_data?.data?.account_info || [];
  const recommendationNotes = analysis_data?.data?.recommendation_notes || analysis_data?.recommendation_notes || [];
  const recommendation_metrics = create_recommendation_metrics(analysis_data)
  console.log("additionalMetrics")
  console.log(JSON.stringify(additionalMetrics))
  console.log("analysis_data")
  console.log(JSON.stringify(analysis_data))

  if (isLoading) {
    return <Loader />;
  }

  if (!additionalMetrics.length) {
    return (
      <>
        <ZeroStateComponent
          tableTitle="Key Metrics"
          tableContent="Analysis Summary not generated yet. Trigger analysis from the Data Section."
        />
        <RecommendationNotes recommendation_notes={recommendationNotes} />
      </>
    );
  }

  return (
    <div>
      <div className="summary-insights">
        <div className="header-row">
          <div className="key-metrics">Key Metrics</div>
          <Button 
            label={reportUrl ? 'DOWNLOAD CAM' : isGenerating ? 'GENERATING...' : 'GENERATE CAM'} 
            className="generate-report-button"
            onClick={reportUrl ? handleDownloadReport : handleGenerateReport}
            disabled={isGenerating}
          />
        </div>
        <div className="metrics-grid">
          {recommendation_metrics.map((metric, index) => (
            <MetricBox key={index} title={metric.Metric} value={metric.Value} />
          ))}
        </div>
        <div className="metrics-grid">
          {additionalMetrics.map((metric, index) => (
            <MetricBox key={index} title={metric.Metric} value={metric.Value} />
          ))}
        </div>
       
        {accountInfo.length > 0 && (
          <div className="content-row">
            <div className="content-column">
              {renderTable(accountInfo, 'Bank Account Details', ['account_no', 'Bank Name', 'From', 'To'])}
            </div>
          </div>
        )}
        {revenueChartData.length > 0 && (
          <div className="content-row">
            <div className="content-column">
              <h3>Revenue Growth</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={revenueChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="revenue" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        {recommendationNotes && <RecommendationNotes recommendation_notes={recommendationNotes} />}
      </div>
    </div>
  );
};

export default AnalysisSummary;
