import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginModal.css';
import { userLogin } from '../../api/apiServices';  // Verify this path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LoginModal = ({ setIsAuthenticated, 
  // onClose 
}) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setIsAuthenticated(true);
      navigate('/');
    }
  }, [navigate, setIsAuthenticated]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const loginResponse = await userLogin(username, password);
      if (loginResponse.success) {
        localStorage.setItem('accessToken', loginResponse.body.data.access_token);
        setIsAuthenticated(true);
        // onClose(); // Close the modal on successful login
        navigate('/');
      } 
      // else {
      //   setError('Invalid credentials, please try again.');
      // }
    } catch (error) {
      // setError('Login error: ' + error.message);
      setError('Invalid credentials, please try again.');
      console.error(error.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-modal-backdrop">
      <div className="login-modal">
        {/* <button className="close-btn" onClick={onClose}>&times;</button> */}
        <h1>Login to Kreditmind</h1>
        {error && <div className="login-error">{error}</div>}
        <form onSubmit={handleLogin} className="login-form">
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" value={username} onChange={e => setUsername(e.target.value)} required />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type={showPassword ? 'text' : 'password'} id="password" value={password} onChange={e => setPassword(e.target.value)} required />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={togglePasswordVisibility}
              className="password-icon"
            />
          </div>
          <button type="submit" className="login-btn">Log In</button>
        </form>

      </div>
    </div>
  );
};

export default LoginModal;
