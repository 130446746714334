import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import './BankingForecastInsights.css';
import ZeroStateComponent from '../../ZeroStateComponent/ZeroStateComponent'

Chart.register(...registerables);

const BankingForecastInsights = ({ customer_uuid, insights_data }) => {
  const [isLoading, setIsLoading] = useState(false);

  const transactionForecast = insights_data?.data?.transaction_forecast || [];

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
  };

  const renderTable = (data, title, columns) => (
    <div className="table-section">
      <h3>{title}</h3>
      <table>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className={row.Type === 'Forecast' ? 'forecast-row' : 'actual-row'}>
              {columns.map((col, idx) => (
                <td key={idx} className={idx === 0 ? 'first-column' : ''}>{col === 'Transaction Date' ? formatDate(row['trans_ts_date']) : row[col]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const processChartData = (data, key) => {
    return {
      labels: data.map((item, index) => {
        if (index % 2 === 0) {
          return formatDate(item.trans_ts_date);
        } else {
          return '';
        }
      }),
      datasets: [
        {
          label: key,
          data: data.map(item => parseFloat(item[key].replace(/,/g, ''))),
          fill: false,
          borderColor: '#4CAF50',
          tension: 0.1
        }
      ]
    };
  };

  return (
    <div className="banking-forecast">
      {isLoading ? (
        <div className="loader-container">
          <div className="loader-bar"></div>
        </div>
      ) : (
        <>
          {transactionForecast.length === 0 ? (
            <ZeroStateComponent tableTitle="Transaction Forecast" tableContent="Transaction Forecast not found.Please upload the bank statements to get the insights" />
            // <div className="zero-state">
            //   <p>Transaction Forecast not found.Please upload the bank statements to get the insights.</p>
            // </div>
          ) : (
            <>
              <div className="content-row">
                <div className="chart-section">
                  <h3>Revenue Forecast</h3>
                  <Line data={processChartData(transactionForecast, 'Total Revenue')} />
                </div>
                <div className="chart-section">
                  <h3>Business Expense Forecast</h3>
                  <Line data={processChartData(transactionForecast, 'Total Expense')} />
                </div>
                <div className="chart-section">
                  <h3>ABB Forecast</h3>
                  <Line data={processChartData(transactionForecast, 'ABB')} />
                </div>
              </div>
              {transactionForecast.length > 0 && renderTable(transactionForecast, 'Transaction Forecast', ['Type', 'Total Revenue', 'Total Expense', 'ABB', 'Transaction Date'])}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BankingForecastInsights;
