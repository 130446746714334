import React, { useState, useEffect } from 'react';
import { getAnalysis } from '../../api/apiServices'; // Make sure you have this function implemented
import './BureauDataInsights.css';
import ZeroStateComponent from '../ZeroStateComponent/ZeroStateComponent';

const BureauDataInsights = ({ customer_uuid, insights_data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [warning, setWarning] = useState(null);

  useEffect(() => {
    console.info("Rendering to fetch bureau data:");
    console.log(JSON.stringify(insights_data));

    if (!insights_data || !insights_data.data) {
      setWarning('Bureau data not found. Please upload to get the insights.');
    } else {
      setWarning(null);
    }
    
    setIsLoading(false);
  }, [customer_uuid, insights_data]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
  };

  const renderTable = (data, title, columns) => (
    <div className="table-section">
      <h3>{title}</h3>
      <table>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((col, idx) => (
                <td key={idx}>{col === 'Pull Date' ? formatDate(row[col]) : row[col]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const personalSummary = insights_data?.data?.personal_summary || [];
  const personalBureau = insights_data?.data?.personal_bureau || [];

  return (
    <div className="bureau-insights">
      {isLoading ? (
        <div className="loader-container">
          <div className="loader-bar"></div>
        </div>
      ) : warning ? (
        // <div className="warning-message">{warning}</div>
        // <div className="warning-message">

        <ZeroStateComponent tableTitle="Bureau Data" tableContent={warning} />
        // </div> 
      ) : (
        <>
          <div className="content-row">
            <div className="content-column">
              {personalSummary.length > 0 ? renderTable(personalSummary, 'Personal Summary', ['Info', 'Value']) : <ZeroStateComponent tableTitle="Personal Summary" tableContent="No Personal Summary Data Available" />}
            </div>
          </div>
          <div className="content-row">
            <div className="content-column">
              {personalBureau.length > 0 ? renderTable(personalBureau, 'Personal Bureau', ['Metric', 'Value']) : <ZeroStateComponent tableTitle="Personal Bureau" tableContent="No Personal Bureau Data Available" />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BureauDataInsights;