import React, { useState, useEffect } from 'react';
import styles from './BankingFraudAnalysis.module.css';
import { FaChartBar } from 'react-icons/fa';
import { performFraudCheck } from '../../../api/apiServices';
import Loader from '../../Loader/Loader'
import ZeroStateComponent from '../../ZeroStateComponent/ZeroStateComponent';


const BankingFraudAnalysis = ({ customer_uuid, insights_data }) => {
  const [accountDetails, setAccountDetails] = useState([]);
  const [unlikelyScenarios, setUnlikelyScenarios] = useState([]);
  const [calculationViolation, setCalculationViolation] = useState([]);
  const [rbiRulesViolation, setRbiRulesViolation] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await performFraudCheck(customer_uuid);
        const account_info = data.data.bank_account_details;
        const fraud_analysis = data.data.fraud_analysis;

        setAccountDetails(account_info.map(info => ({
          name: info.account_holder_name,
          accountNumber: info.account_no,
          bank: info.bank_name,
          accountType: 'Current Account',
          fraudAnalyticsStatus: 'VALID'
        })));

        setUnlikelyScenarios(fraud_analysis.unlikely_scenarios);
        setCalculationViolation(fraud_analysis.calculation_violations);
        setRbiRulesViolation(fraud_analysis.rbi_rules_violations);
      } catch (error) {
        console.error('Error fetching fraud check data:', error);
      }
    };

    fetchData();
  }, [customer_uuid]);

  useEffect(() => {
    if ((unlikelyScenarios.length > 0 && calculationViolation.length > 0 && rbiRulesViolation.length > 0)) {
      setLoading(false); // Set loading to false when all data is available
    }
  }, [unlikelyScenarios, calculationViolation, rbiRulesViolation]);

  const accountColumns = ['Name', 'Account Number', 'Bank'];

  const getObjectKey = (colName) => {
    switch (colName) {
      case 'Name': return 'name';
      case 'Account Number': return 'accountNumber';
      case 'Bank': return 'bank';
      // case 'Account Type': return 'accountType';
      default: return colName.toLowerCase().replace(/\s+/g, '');
    }
  };

  const renderTable = (data, title) => (
    <div className={styles.subSection_bfa}>
      <h3 className={styles.subHeader_bfa}>{title}</h3>
      <table className={styles.analysis_table_bfa} style={{ maxWidth: '100%' }}>
        <thead>
          <tr>
            <th>Description</th>
            <th>Status</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => (
            <tr key={idx}>
              <td>{item.description}</td>
              <td>{item.status}</td>
              <td>{item.remarks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <>
    {loading? (
          <>
            <Loader />
          </>
        ) : (
    <div className={styles.container_bfa}>
      <div className={styles.section_bfa}>
        <h2 className={styles.title}>
          <FaChartBar className={styles.icon} />
          Account Details and Verification Status
        </h2>
        {accountDetails.length === 0 ? (
          <ZeroStateComponent />
        ) : (
        <table className={styles.table_bfa} style={{ maxWidth: '100%' }}>
          <thead>
            <tr>
              {accountColumns.map((col, index) => (
                <th key={index}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {accountDetails.map((row, index) => (
              <tr key={index}>
                {accountColumns.map((col, idx) => (
                  <td key={idx}>{row[getObjectKey(col)]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>)}
      </div>

      <div className={styles.section_bfa}>
        <h2 className={styles.title}>
          <FaChartBar className={styles.icon} />
          Analysis Result
        </h2>
        <div className={styles.horizontalTables_bfa}>
          {renderTable(unlikelyScenarios, 'Unlikely Scenarios')}
          {renderTable(calculationViolation, 'Calculation Violation')}
          {renderTable(rbiRulesViolation, 'RBI Rules Violation')}
        </div>
      </div>
    </div>)}
    </>
  );
};

export default BankingFraudAnalysis;