import React, { useState, useEffect, useRef } from 'react';
import { FaTimes, FaChartBar, FaFilter } from 'react-icons/fa'; // Removed FaCalendarAlt from import
import SkeletonLoader from '../../loaders/skeltons/SkeletonLoader';
import ZeroStateComponent from '../../ZeroStateComponent/ZeroStateComponent';
import * as XLSX from 'xlsx'; // Import the xlsx library
import styles from './BankingRepayments.module.css';
import Loader from '../../Loader/Loader';

const BankingRepayments = ({ customer_uuid, insights_data }) => {
  const [bankingrepaymentData, setBankingrepaymentData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filters, setFilters] = useState({
    final_party_name: [],
    repayment_type: [],
    transaction_date: '',
    amount: '',
  });

  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState({
    final_party_name: false,
    repayment_type: false,
  });

  const dropdownRefs = useRef({});

  useEffect(() => {
    const extractData = async () => {
      try {
        setIsLoading(true);

        if (insights_data && insights_data.data) {
          const repayments = insights_data.data.repayments || [];
          setBankingrepaymentData(repayments);
          setDisplayData(repayments);
        } else {
          setBankingrepaymentData([]);
          setDisplayData([]);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching Categorized Transactions data:', error);
        setIsLoading(false);
      }
    };

    extractData();
  }, [customer_uuid, insights_data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(dropdownRefs.current).forEach((key) => {
        if (dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)) {
          setIsFilterDropdownOpen((prev) => ({
            ...prev,
            [key]: false,
          }));
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let filteredData = bankingrepaymentData;

    if (filters.final_party_name.length > 0) {
      filteredData = filteredData.filter((item) =>
        filters.final_party_name.includes(item.final_party_name)
      );
    }

    if (filters.repayment_type.length > 0) {
      filteredData = filteredData.filter((item) =>
        filters.repayment_type.includes(item.repayment_type)
      );
    }

    if (filters.transaction_date) {
      filteredData = filteredData.filter((item) =>
        item.transaction_date.startsWith(filters.transaction_date)
      );
    }

    if (filters.amount) {
      const value = parseFloat(filters.amount);
      filteredData = filteredData.filter((item) => parseFloat(item.amount) === value);
    }

    setDisplayData(filteredData);
  }, [filters, bankingrepaymentData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRecordsPerPageChange = (event) => {
    setRecordsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleDropdownFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: prevFilters[key].includes(value)
        ? prevFilters[key].filter((item) => item !== value)
        : [...prevFilters[key], value],
    }));
  };

  const clearDateFilter = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      transaction_date: '',
    }));
  };

  const toggleFilterDropdown = (key) => {
    setIsFilterDropdownOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const formatValue = (value) => parseFloat(value).toLocaleString('en-IN', { style: 'currency', currency: 'INR' });

  const startIndex = (currentPage - 1) * recordsPerPage;
  const currentTransactions = displayData.slice(startIndex, startIndex + recordsPerPage);
  const totalPages = Math.ceil(displayData.length / recordsPerPage);

  const tableHeaderStyle = {
    amount: { width: '250px' },
    final_party_name: { width: '250px' },
    repayment_type: { width: '230px' },
    transaction_date: { width: '230px' },
  };

  const uniqueValues = (key) => {
    return [...new Set(bankingrepaymentData.map((item) => item[key]))];
  };

  const renderDropdownOptions = (key) => {
    return (
      <div className={styles.filterDropdown} ref={(el) => (dropdownRefs.current[key] = el)}>
        {uniqueValues(key).map((value) => (
          <label key={value} className={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={filters[key].includes(value)}
              onChange={() => handleDropdownFilterChange(key, value)}
            />
            {value}
          </label>
        ))}
      </div>
    );
  };

  const renderTable = (data) => {
    return (
      <div className={styles.tableSection}>
        <table className={styles.bankingDataTable} style={{ maxWidth: '100%' }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle.amount}>Amount</th>
              <th style={tableHeaderStyle.final_party_name}>Party Name</th>
              <th style={tableHeaderStyle.repayment_type}>Transaction Type</th>
              <th style={tableHeaderStyle.transaction_date}>Transaction Date</th>
            </tr>
            <tr className={styles.filtersRow}>
              <th>
                <input
                  type="number"
                  placeholder="Amount"
                  onChange={(e) => handleFilterChange('amount', e.target.value)}
                  value={filters.amount}
                  className={styles.amountInput}
                />
              </th>
              <th>
                <FaFilter className={styles.filterIcon} onClick={() => toggleFilterDropdown('final_party_name')} />
                {isFilterDropdownOpen.final_party_name && renderDropdownOptions('final_party_name')}
              </th>
              <th>
                <FaFilter className={styles.filterIcon} onClick={() => toggleFilterDropdown('repayment_type')} />
                {isFilterDropdownOpen.repayment_type && renderDropdownOptions('repayment_type')}
              </th>
              <th>
                <div className={styles.dateFilter}>
                  <input
                    type="date"
                    onChange={(e) => handleFilterChange('transaction_date', e.target.value)}
                    className={styles.dateInput}
                    value={filters.transaction_date}
                  />
                  {filters.transaction_date && (
                    <FaTimes className={styles.clearIcon} onClick={clearDateFilter} />
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan="4">
                  <ZeroStateComponent />
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index} className={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                  <td>{formatValue(item.amount)}</td>
                  <td>{item.final_party_name}</td>
                  <td>{item.repayment_type}</td>
                  <td>{item.transaction_date}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {data.length === 0 ? (
          <div></div>
        ) : (
          <div className={styles.pagination}>
            <label>
              Show
              <select value={recordsPerPage} onChange={handleRecordsPerPageChange}>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
              </select>
              items per page
            </label>
            <div className={styles.pageControls}>
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
              )}
              {currentPage > 3 && <span>...</span>}
              {Array.from({ length: Math.min(3, totalPages) }, (_, i) => {
                const pageNumber = i + Math.max(1, currentPage - 1);
                return (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={pageNumber === currentPage}
                  >
                    {pageNumber}
                  </button>
                );
              })}
              {currentPage < totalPages - 2 && <span>...</span>}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
              )}
            </div>
          </div>)}
      </div>
    );
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(bankingrepaymentData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Repayments");
    XLSX.writeFile(workbook, `Repayments_${customer_uuid}.xlsx`);
  };

  return (
    <div>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          {/* <SkeletonLoader /> */}
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <h2 className={styles.title}>
              <FaChartBar className={styles.icon} />
              Repayments
            </h2>
            {bankingrepaymentData.length > 0 && (
              <button className={styles.lpDownloadButton} onClick={downloadExcel}>
                Download Data
              </button>
            )}
          </div>
          {renderTable(currentTransactions)}
        </>
      )}
    </div>
  );
};

export default BankingRepayments;
