import React, { useState, useEffect } from 'react';
import styles from './GSTInsights.module.css'; // Ensure the correct path to the CSS file
import { FaChartBar } from 'react-icons/fa';
import Loader from '../../Loader/Loader';
import ZeroStateComponent from '../../ZeroStateComponent/ZeroStateComponent';
const _ = require('lodash');

const GSTInsights = ({ customer_uuid, insights_data }) => {
  const [gstData, setGstData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [warning, setWarning] = useState(null);

  useEffect(() => {
    const extractData = () => {
      try {
        setIsLoading(true);

        if (insights_data && insights_data.data) {
          console.log('Insights data:', insights_data);
          const revenue_data = insights_data.data.table2;
          const purchase_data = insights_data.data.table3;
          const top_customer = insights_data.data.table5;
          const top_vendor = insights_data.data.table6;

          if (!_.isEmpty(revenue_data) && !_.isEmpty(purchase_data) && !_.isEmpty(top_customer) && !_.isEmpty(top_vendor)) {
            console.log('Revenue data:', revenue_data);
            console.log('Purchase data:', purchase_data);
            console.log('Top customer data:', top_customer);
            console.log('Top vendor data:', top_vendor);

            const groupedRevenueData = revenue_data.reduce((acc, item) => {
              const gstNumber = Object.keys(item).find(key => key !== 'Return Period' && key !== 'Total');
              if (!acc[gstNumber]) {
                acc[gstNumber] = [];
              }
              acc[gstNumber].push({
                returnPeriod: item['Return Period'],
                totalRevenue: item[gstNumber],
              });
              return acc;
            }, {});

            const groupedPurchaseData = purchase_data.reduce((acc, item) => {
              const gstNumber = Object.keys(item).find(key => key !== 'Return Period' && key !== 'Total');
              if (!acc[gstNumber]) {
                acc[gstNumber] = [];
              }
              acc[gstNumber].push({
                returnPeriod: item['Return Period'],
                totalExpense: item[gstNumber],
              });
              return acc;
            }, {});

            const allPeriods = [...new Set([...revenue_data.map(item => item['Return Period']), ...purchase_data.map(item => item['Return Period'])])];

            const grossData = allPeriods.map(period => {
              let totalRevenue = 0;

              for (const gstNumber in groupedRevenueData) {
                const revenueEntry = groupedRevenueData[gstNumber].find(entry => entry.returnPeriod === period);
                if (revenueEntry) totalRevenue += revenueEntry.totalRevenue;
              }

              return { returnPeriod: period, totalRevenue };
            });

            const transformedTopCustomer = top_customer.map(item => ({
              name: item.Name,
              taxableValue: item['Taxable Value'],
              totalInvoices: item['Total Invoices']
            }));

            const transformedTopVendor = top_vendor.map(item => ({
              name: item.Name,
              taxableValue: item['Taxable Value'],
              totalInvoices: item['Total Invoices']
            }));

            setGstData({
              groupedRevenueData,
              grossData,
              transformedTopCustomer,
              transformedTopVendor
            });

            console.log('GST data state set:', {
              groupedRevenueData,
              grossData,
              transformedTopCustomer,
              transformedTopVendor
            });
          } else {
            setWarning('GST report not found. Please upload to get the insights.');
          }
        } else {
          setWarning('GST report not found. Please upload to get the insights.');
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching GST data:', error);
        setWarning('Error fetching GST data');
        setIsLoading(false);
      }
    };

    extractData();
  }, [insights_data]);

  const formatValue = (value, asCurrency = false) => {
    if (isNaN(value)) return asCurrency ? '₹0.00' : '0.00';
  
    return asCurrency
      ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(value)
      : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
  };

  const renderTable = (data, title, columns, className) => (
    <div className={styles.gstTableSection}>
      <h2 className={styles.title}>
        <FaChartBar className={styles.icon} />
        {title}
      </h2>
      <table className={className} style={{ maxWidth: '100%' }}>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index} className={styles[`column${col.replace(/\s+/g, '')}`]}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((col, idx) => (
                <td key={idx} className={styles[`column${col.replace(/\s+/g, '')}`]}>{row[col]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const gstins = insights_data?.data?.table1 || [];
  const additionalTable4 = insights_data?.data?.table4 || [];

  return (
    <div style={{paddingBottom: '20px'}}>
      {isLoading ? (
        <Loader/>
      ) : warning ? (
        <div className={styles.warningMessage}>
          <ZeroStateComponent tableTitle="GST Report" tableContent={warning} />
        </div>
      ) : (
        gstData && (
          <>
            <div className={styles.tableContainer}>
              <div className={styles.gstListContainer}>
                {gstins.length > 0 && renderTable(gstins, 'GSTINs', ['GSTINS', 'Compliance', 'Last Filing', 'Filing Frequency', 'Registration Date'], styles.gstinsTable)}
              </div>
            </div>
            <div className={styles.tableContainer}>
              <div className={styles.gstMetricsContainer}>
                {additionalTable4.length > 0 && renderTable(additionalTable4, 'GST Metrics', ['Metric', 'Value'], styles.metricsTable)}
              </div>
            </div>
            <div className={styles.tableContainer}>
              <div className={styles.gstRevenueExpenseTableSection}>
                <h2 className={styles.title}>
                  <FaChartBar className={styles.icon} />
                  Revenue by GSTIN
                </h2>
                <table className={styles.revenueExpenseTable} style={{ maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      <th className={styles.columnPeriod}>Return Period</th>
                      {Object.keys(gstData.groupedRevenueData).map((gstNumber, index) => (
                        <th key={`revenue-${index}`} className={styles.columnGst}>{gstNumber}</th>
                      ))}
                      <th className={styles.columnGross}>Total Revenue (&#8377;)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gstData.grossData.map((item, index) => (
                      <tr key={index}>
                        <td className={styles.columnPeriod}>{item.returnPeriod}</td>
                        {Object.keys(gstData.groupedRevenueData).map((gstNumber, idx) => {
                          const revenueEntry = gstData.groupedRevenueData[gstNumber].find(entry => entry.returnPeriod === item.returnPeriod);
                          return (
                            <React.Fragment key={`data-${index}-${idx}`}>
                              <td className={styles.columnGst}>₹{formatValue(revenueEntry ? revenueEntry.totalRevenue : 0)}</td>
                            </React.Fragment>
                          );
                        })}
                        <td className={styles.columnGross}>₹{formatValue(item.totalRevenue)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={styles.tableContainer}>
              <div className={styles.contentRow}>
                <div className={styles.contentColumn}>
                  <div className={styles.gstTableSection}>
                    <h2 className={styles.title}>
                      <FaChartBar className={styles.icon} />
                      Top Customers
                    </h2>
                    <table className={styles.customersTable} style={{ maxWidth: '100%' }}>
                      <thead>
                        <tr>
                          <th className={styles.customerColumnName}>Name</th>
                          <th className={styles.customerColumnTaxableValue}>Taxable Value (&#8377;)</th>
                          <th className={styles.customerColumnTotalInvoices}>Total Invoices</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gstData.transformedTopCustomer.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td className={styles.columnTaxableValue}>₹{formatValue(item.taxableValue)}</td>
                            <td className={styles.columnTotalInvoices}>{item.totalInvoices}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.tableContainer}>
              <div className={styles.contentRow}>
                <div className={styles.contentColumn}>
                  <div className={styles.gstTableSection}>
                    <h2 className={styles.title}>
                      <FaChartBar className={styles.icon} />
                      Top Vendors
                    </h2>
                    <table className={styles.vendorsTable} style={{ maxWidth: '100%' }}>
                      <thead>
                        <tr>
                          <th className={styles.vendorColumnName}>Name</th>
                          <th className={styles.vendorColumnTaxableValue}>Taxable Value (&#8377;)</th>
                          <th className={styles.vendorColumnTotalInvoices}>Total Invoices</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gstData.transformedTopVendor.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td className={styles.columnTaxableValue}>₹{formatValue(item.taxableValue)}</td>
                            <td className={styles.columnTotalInvoices}>{item.totalInvoices}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default GSTInsights;
