// Function to check if an item is enabled for the user based on features_enabled in the decoded token
export const isItemEnabled = (item, decodedPayload) => {
    const enabledFeatures = decodedPayload?.features_enabled || [];
    return enabledFeatures.includes(item.key);
  };
  
  // Function to get enabled accordion items based on the features_enabled in the decoded token
  export const getEnabledAccordionItems = (accordionData, decodedPayload) => {
    return accordionData.map(item => ({
      ...item,
      enabled: isItemEnabled(item, decodedPayload),
    }));
  };
  