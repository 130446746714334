// Button.jsx

import React from 'react';
import './Button.css'; // Make sure to create an accompanying .css file

const Button = ({ label, onClick, className = '' }) => {
  return (
    <button className={`button ${className}`} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;
