// Toast.js
import React from 'react';
import './Toast.css'; // Make sure this import path is correct

const Toast = ({ message, onClose, type }) => {
  return (
    <div className={`toast ${type}`} onClick={onClose}>
      {message}
    </div>
  );
};

export default Toast;
