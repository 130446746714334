import React from 'react';
import { FaMehBlank, FaChartBar } from 'react-icons/fa';
import styles from './ZeroStateComponent.module.css';

const ZeroStateComponent = ({ tableTitle, tableContent }) => {
  return (
    <div>
      <h2 className={styles.title}>
        {/* {tableTitle && <FaChartBar className={styles.icon} />} */}
        {tableTitle}
      </h2>
    
      <div className={styles.zeroState}>
        {tableContent ? <p>{tableContent}</p> : <p>No Results Found.</p>}
      </div>
    </div>
  );
};

export default ZeroStateComponent;