import React, { useState, useEffect, useRef } from 'react';
import styles from './OnDemandAnalysis.module.css'; // Ensure this path matches your CSS module file
import { get_answers } from '../../api/apiServices';  // Verify this path

const OnDemandAnalysis = ({ isMinimized, toggleMinimize, customer_uuid, email_id }) => {
  const localStorageKey = `chatMessages-${customer_uuid || email_id || 'anonymous'}`; // Use email_id or fallback to anonymous
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem(localStorageKey);
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [currentMessage, setCurrentMessage] = useState('');
  const [sourceId, setSourceId] = useState(null); // Added to store sourceId
  const messagesEndRef = useRef(null);
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const ChatIconSVG = () => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 3C7.03 3 3 6.81 3 11.5C3 14.11 4.46 16.5 6.5 18.14V21C6.5 21.55 6.95 22 7.5 22C7.78 22 8.04 21.89 8.23 21.7L11.64 18.29C11.84 18.09 12.14 18 12.44 18H16.5C17.88 18 19.21 17.58 20.3 16.88C20.96 16.48 21.55 15.95 22 15.3C22.63 14.44 23 13.45 23 12.4C23 11.34 22.63 10.35 22 9.49C21.55 8.84 20.96 8.31 20.3 7.91C19.21 7.21 17.88 6.79 16.5 6.79C15.56 6.79 14.66 6.94 13.81 7.22C13.01 7.5 12.29 7.89 11.66 8.37C11.2 8.72 10.79 9.12 10.45 9.56C9.67 8.95 8.72 8.49 7.7 8.18C9.08 6.37 10.82 5 12.85 4.16C14.88 3.33 17.08 3 19 3H12Z" fill="currentColor"/>
    </svg>
  );

  const MinimizeIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M12 5v14"></path>
      <polyline points="19 12 12 19 5 12"></polyline>
    </svg>
  );

  const SendIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M22 2L11 13" />
      <path d="M22 2L15 22 11 13 2 9 22 2z" />
    </svg>
  );

  useEffect(() => {
    scrollToBottom();
    localStorage.setItem(localStorageKey, JSON.stringify(messages));
  }, [messages, localStorageKey]);

  const handleToggle = () => {
    toggleMinimize();
  };

  const handleSendMessage = async () => {
    if (currentMessage.trim() !== '') {
      const newMessages = [...messages, { text: currentMessage, from: 'user' }];
      setMessages(newMessages);
      setCurrentMessage('');
      setWaitingForResponse(true);
  
      try {
        const chatResponse = await get_answers(customer_uuid, email_id, currentMessage, sourceId);
        const { message, sourceId: newSourceId, answer } = chatResponse.data;
  
        if (newSourceId) {
          setSourceId(newSourceId);
        }
  
        const responseMessage = answer || message;
        setMessages(messages => [...messages, { text: responseMessage, from: 'system' }]);
      } catch (error) {
        console.error('Failed to fetch the chat response:', error);
        setMessages(messages => [...messages, { text: 'I am still learning. Try something else while we are checking the root cause.', from: 'system' }]);
      }
      setWaitingForResponse(false);
    }
  };
  const formatResponse = (response) => {
    // Regular expression to match full URLs, including paths and query strings
    const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
  
    // Split response by URLs and return JSX
    return response.split(urlPattern).map((part, index) => {
      if (urlPattern.test(part)) {
        // Ensure that 'www.' URLs are converted to clickable links with 'https://'
        const href = part.startsWith('www.') ? `https://${part}` : part;
        return (
          <a key={index} href={href} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };
  
  
  
  
  

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      handleSendMessage();
    }
  };

  return (
    <div>
      {isMinimized ? (
        <div className={styles.minimizedCircle} onClick={toggleMinimize}>
          <ChatIconSVG />
        </div>
      ) : (
        <div className={`${styles.analysis}`}>
          <div className={styles.header}>
            <span>Sage</span>
            <button onClick={handleToggle} className={styles.toggleButton}>
              <MinimizeIconSVG />
            </button>
          </div>
          <div className={styles.messagesList}>
            {messages.length === 0 && (
              <div className={styles.startMessage}>
                👋 Say "Start" to start asking me anything related to your loan 🚀
              </div>
            )}
            {messages.map((message, index) => (
              <div key={index} className={`${styles.message} ${styles[message.from]}`}>
                {formatResponse(message.text)}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              type="text"
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              onKeyDown={handleKeyDown} // Added to send message on pressing Enter
              placeholder={waitingForResponse ? "Getting response..." : "Type your query..."} // Placeholder text when waiting for response
              disabled={waitingForResponse} // Disable input while waiting for response
            />
            <button
              className={styles.sendButton}
              onClick={handleSendMessage}
              disabled={waitingForResponse || (messages.length === 0 && currentMessage.trim().toLowerCase() !== 'start')}
            >
              <SendIconSVG /> {/* Replaced the button with an icon */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnDemandAnalysis;
