import React, { useState, useEffect, useRef } from 'react';
import './DocumentUploader.css';
import UploadModal from './UploadModal';
import AnalysisSummary from '../insights/AnalysisSummary';
import { uploadDocument, analyzeData, getUploadedFiles, getStatus } from '../../api/apiServices';
import { decodeJWT } from '../../utils/jwtUtils';
import { getCustomerDetails, getAnalysis, getInsight } from '../../api/apiServices';
import { Tooltip } from 'react-tooltip';
import Loader from '../Loader/Loader';

const DocumentUploader = ({ onSelectTab, customerUuid, fetchData, onDataReady }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [serverFiles, setServerFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [progress, setProgress] = useState(1);
  const [progressBarColor, setProgressBarColor] = useState('#4caf50');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentStep, setCurrentStep] = useState('upload');
  const [isLoading, setIsLoading] = useState(true);

  const [analysisData, setAnalysisData] = useState(null);
  const [insightsData, setInsightsData] = useState(null);
  const [showAnalysisSummary, setShowAnalysisSummary] = useState(false); // State to manage view switching

  const messageIndexRef = useRef(0);
  const messageIntervalRef = useRef(null);
  const decodedToken = decodeJWT();
  const featuresEnabled = decodedToken?.features_enabled || [];

  const progressMessages = {
    "upload": [
      "Starting upload...",
      "Uploading your files...",
      "Files are being uploaded...",
      "Almost done with the upload..."
    ],
    "progress": {
      "0-25": [
        "Analyzing the uploaded data...",
        "Preparing data for analysis..."
      ],
      "26-40": [
        "Processing initial data...",
        "Breaking down the information...",
        "Data examination in progress..."
      ],
      "41-60": [
        "Halfway through the analysis...",
        "Data analysis underway...",
        "Inspecting data for insights...",
        "Interpreting the data...",
        "Crunching the numbers..."
      ],
      "61-84": [
        "Extracting valuable insights...",
        "Fine-tuning the results...",
        "Data analysis ongoing...",
        "Reviewing data patterns...",
        "Assessing the data...",
        "Working on the analysis..."
      ],
      "85-94": [
        "Continuing data analysis...",
        "Insights generation in progress...",
        "Data insights being formulated...",
        "Almost halfway done...",
        "Progressing with the analysis..."
      ],
      "95-100": [
        "Finalizing the analysis...",
        "Wrapping up the data processing...",
        "Completing the analysis...",
        "Final touches being added...",
        "Analysis nearing completion..."
      ]
    }
  };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const filesFromServer = await getUploadedFiles(customerUuid, "all");
        setServerFiles(filesFromServer.data.map(file => ({ ...file, isServerFile: true })));
      } catch (error) {
        console.error('Failed to fetch uploaded files:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFiles();
  }, [customerUuid]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const addUploadedFile = (filesData) => {
    if (!Array.isArray(filesData)) {
      console.error('Expected filesData to be an array, but received:', filesData);
      return;
    }

    const newFiles = filesData.map(({ file, password_required, password, dataType, document_type_code }) => ({
      file,
      password_required,  // Add password_required to the file object
      password,           // Add password to the file object
      dataType,
      document_type_code,
      isServerFile: false
    }));

    setUploadedFiles(prev => [...prev, ...newFiles]);
  };

  const getNextMessage = (step) => {
    if (step === 'upload') {
      const messages = progressMessages[step];
      const message = messages[messageIndexRef.current % messages.length];
      messageIndexRef.current++;
      return message;
    }

    if (step === 'progress') {
      let messages;
      if (progress <= 25) {
        messages = progressMessages.progress["0-25"];
      } else if (progress <= 40) {
        messages = progressMessages.progress["26-40"];
      } else if (progress <= 60) {
        messages = progressMessages.progress["41-60"];
      } else if (progress <= 84) {
        messages = progressMessages.progress["61-84"];
      } else if (progress <= 94) {
        messages = progressMessages.progress["85-94"];
      } else {
        messages = progressMessages.progress["95-100"];
      }

      const message = messages[messageIndexRef.current % messages.length];
      messageIndexRef.current++;
      return message;
    }
  };

  const updateProgressMessage = (step) => {
    setProgressMessage(getNextMessage(step));
  };

  const startMessageInterval = (step) => {
    if (messageIntervalRef.current) {
      clearInterval(messageIntervalRef.current);
    }
    messageIntervalRef.current = setInterval(() => {
      updateProgressMessage(step);
    }, 5000);
  };

  useEffect(() => {
    if (currentStep === 'progress') {
      updateProgressMessage('progress');
    }
  }, [progress]);

  const handleProceed = async () => {
    setIsUploading(true);
    setErrorMessage('');
    setCurrentStep('upload');
    updateProgressMessage('upload');
    startMessageInterval('upload');

    try {
      const successfullyUploadedFiles = [];
      for (const uploadedFile of uploadedFiles) {
        if (!uploadedFile.isServerFile) {
          await uploadDocument(
            uploadedFile.file,
            uploadedFile.dataType,
            uploadedFile.document_type_code,
            customerUuid,
            uploadedFile.password_required,
            uploadedFile.password
          );
          setProgress((prev) => Math.min(prev + 3, 96));
          successfullyUploadedFiles.push(uploadedFile);
        }
      }

      setServerFiles((prev) => [
        ...prev,
        ...successfullyUploadedFiles.map((file) => ({ ...file, isServerFile: true })),
      ]);
      setUploadedFiles((prev) => prev.filter((file) => !successfullyUploadedFiles.includes(file)));
      
      if (featuresEnabled.includes('analysisSummary')) {
        setIsUploading(false);
        setIsAnalyzing(true);
        setCurrentStep('progress');
        updateProgressMessage('progress');
        startMessageInterval('progress');

        const response = await analyzeData(customerUuid);
        const taskId = response.data.task_id;

        const checkStatus = async () => {
          try {
            const statusResponse = await getStatus(taskId);
            if (statusResponse.data.status === 'completed') {
              clearInterval(statusInterval);
              clearInterval(messageIntervalRef.current);

              const analysis_data = await getAnalysis(customerUuid);
              const insights_data = await getInsight(customerUuid);

              setAnalysisData(analysis_data);
              setInsightsData(insights_data);
              setProgress(100);
              setShowAnalysisSummary(true); // Trigger the view change here
              onDataReady(customerUuid, analysis_data, insights_data); // Pass data to Dashboard
            } else {
              const increment = progress >= 96 ? 0 : Math.min(Math.random() * (8 - 4) + 4, 96 - progress);
              setProgress((prevProgress) => Math.min(prevProgress + increment, 96));
            }
          } catch (error) {
            console.error('Error while checking status:', error);
          }
        };

        const statusInterval = setInterval(checkStatus, 5000);
      } else {
        const simulateProgressToCompletion = () => {
          setProgress((prevProgress) => {
            const increment = prevProgress >= 96 ? 4 : Math.min(Math.random() * (8 - 4) + 4, 100 - prevProgress);
            return Math.min(prevProgress + increment, 100);
          });
        };
        
        const progressInterval = setInterval(() => {
          setProgress((prevProgress) => {
            const increment = prevProgress >= 96 ? 4 : Math.min(Math.random() * (8 - 4) + 4, 100 - prevProgress);
            const newProgress = Math.min(prevProgress + increment, 100);
        
            if (newProgress >= 100) {
              clearInterval(progressInterval);
        
              setTimeout(() => {
                onSelectTab('8');
              }, 2000);
            }
        
            return newProgress;
          });
        }, 500);
        
      }
    } catch (error) {
      console.error('Error during proceed action:', error);
      setProgressBarColor('red');
      setErrorMessage('An error occurred during the process. Please try again.');
      setCurrentStep('error');
      updateProgressMessage('progress');
      clearInterval(messageIntervalRef.current);
      setIsUploading(false);
      setIsAnalyzing(false);
      setProgress(100);
    }
  };

  const handleDeleteFile = (item, index) => {
    if (item.isServerFile) {
      console.log("Api call will be made to Delete the file");
      console.log(JSON.stringify(item));
    }
    setUploadedFiles(prev => prev.filter((_, idx) => idx !== index));
  };

  const handleCheckboxChange = (e) => {
    setAllFilesUploaded(e.target.checked);
  };

  const combinedFiles = [...serverFiles, ...uploadedFiles];

  const getBorderColor = (file) => {
    if (!file.parsing_meta) {
      return 'yellow';
    }
    if (file.parsing_meta.failed_pages > 0) {
      return 'red';
    } else if (file.parsing_meta.failed_pages === 0 && file.parsing_meta.total_pages > 0) {
      return 'green';
    } else {
      return 'yellow';
    }
  };

  const getTooltipMessage = (file) => {
    if (!file.parsing_meta) {
      return 'File processing status unknown';
    }
    if (file.parsing_meta.failed_pages > 0) {
      return `Failed pages: ${file.parsing_meta.failed_page_nos.join(', ')}`;
    } else {
      return 'File processed successfully';
    }
  };

  const zeroStateMessage = "No data available.";

  const progressClass = progress < 50 ? 'progress low' : 'progress high';

  // Conditional rendering based on showAnalysisSummary state
  if (showAnalysisSummary && analysisData && insightsData) {
    return <AnalysisSummary customer_uuid={customerUuid} insights_data={insightsData} analysis_data={analysisData} />;
  }

  return (
    <div className="document-uploader">
      {isLoading ? (
        <div>
          <Loader LoaderContent="Fetching document" />
        </div>
      ) : (
        <>
          <div className="upload-message">
            <p>To proceed, please upload the documents. We are committed to safeguarding the confidentiality and security of your data.</p>
          </div>

          <div className="uploaded-files-container">
            {combinedFiles.length === 0 ? (
              <div className="dc-zero-state">
                <p>{zeroStateMessage}</p>
              </div>
            ) : (
              combinedFiles.map((item, index) => (
                <div key={index} className="uploaded-file-info" style={{ borderColor: getBorderColor(item) }}>
                  <span className="uploaded-file-name" data-tooltip-id={`tooltip-${index}`}>
                    {item.original_filename
                      ? (
                        <>
                          {item.original_filename.length > 20
                            ? `${item.original_filename.substring(0, 15)}...${item.original_filename.substring(item.original_filename.lastIndexOf('.') - 1)}`
                            : item.original_filename}
                        </>
                      )
                      : (
                        <>
                          {item.file.name.length > 20
                            ? `${item.file.name.substring(0, 15)}...${item.file.name.substring(item.file.name.lastIndexOf('.') - 1)}`
                            : item.file.name}
                        </>
                      )
                    }
                  </span>
                  <Tooltip id={`tooltip-${index}`} className="tooltip-custom" style={{ backgroundColor: "black", color: "white" }}>
                    {item.original_filename}
                  </Tooltip>
                  <div className="file-actions">
                    {item.parsing_meta && item.parsing_meta.failed_pages > 0 && (
                      <>
                        <span className="error-icon" data-tooltip-id={`error-tooltip-${index}`}>
                          <svg fill="red" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="red">
                            <path d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0zM12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2C17.514 2 22 6.486 22 12C17.514 22 12 22 12 22zM12 6C11.448 6 11 6.448 11 7V13C11 13.552 11.448 14 12 14C12.552 14 13 13.552 13 13V7C13 6.448 12.552 6 12 6zM12 16C11.448 16 11 16.448 11 17C11 17.552 11.448 18 12 18C12.552 18 13 17.552 13 17C13 16.448 12.552 16 12 16z"></path>
                          </svg>
                        </span>
                        <Tooltip id={`error-tooltip-${index}`} className="tooltip-custom" style={{ backgroundColor: "black", color: "white" }}>
                          Parsing error at page numbers: {item.parsing_meta.failed_page_nos.join(', ')}
                        </Tooltip>
                      </>
                    )}
                    {item.parsing_meta && item.parsing_meta.failed_pages === 0 && (
                      <>
                        <span className="success-icon" data-tooltip-id={`success-tooltip-${index}`}>
                          <svg fill="none" stroke="green" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C6.485 2 2 6.485 2 12s4.485 10 10 10 10-4.485 10-10S17.515 2 12 2zm-1 15l-5-5 1.414-1.414L11 14.172l6.586-6.586L19 9l-8 8z"></path>
                          </svg>
                        </span>
                        <Tooltip id={`success-tooltip-${index}`} className="tooltip-custom" style={{ backgroundColor: "black", color: "white" }}>
                          File processed successfully
                        </Tooltip>
                      </>
                    )}
                    {!item.isServerFile && (
                      <button onClick={() => handleDeleteFile(item, index)} className="delete-button">
                        <svg fill="#f55151" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#f55151">
                          <path d="M22,5H17V2a1,1,0,0,0-1-1H8A1,1,0,0,0,7,2V5H2A1,1,0,0,0,2,7H3.117L5.008,22.124A1,1,0,0,0,6,23H18a1,1,0,0,0,.992-.876L20.883,7H22a1,1,0,0,0,0-2ZM9,3h6V5H9Zm8.117,18H6.883L5.133,7H18.867Z"></path>
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>

          {combinedFiles.length > 0 && (
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="allFilesUploaded"
                className="checkbox-input"
                checked={allFilesUploaded}
                onChange={handleCheckboxChange}
                disabled={isUploading || isAnalyzing}
              />
              <label htmlFor="allFilesUploaded" className="checkbox-label">
                All relevant files uploaded and ready for analysis.
              </label>
            </div>
          )}

          <div className="fixed-bottom">
            {!allFilesUploaded || combinedFiles.length === 0 ? (
              <button onClick={openModal} className="upload-button">
                {uploadedFiles.length > 0 || serverFiles.length > 0 ? 'Add More' : 'Add Data'}
              </button>
            ) : (
              <button onClick={handleProceed} disabled={isUploading || isAnalyzing} className="upload-button">
                {isAnalyzing ? (
                  <div className="processing-container">
                    <div className="button-loader"></div>
                    <span>Processing</span>
                  </div>
                ) : (
                  'Proceed'
                )}
              </button>
            )}

            <div className={`progress-container ${progressMessage ? 'visible' : ''}`}>
              <div className="progress-bar-container">
                <div className="progress-bar">
                  <div className={progressClass} style={{ width: `${progress}%`, backgroundColor: progressBarColor }}>
                    <span className="progress-percent">{Math.floor(progress)}%</span>
                  </div>
                </div>
              </div>
              <div className="progress-message-container">
                <div className="progress-message">{progressMessage}</div>
              </div>
            </div>
          </div>

          {uploadStatus && <div className="upload-status">{uploadStatus}</div>}
          <UploadModal isOpen={isModalOpen} onClose={closeModal} onFileSelect={addUploadedFile} />
        </>
      )}
    </div>
  );
};

export default DocumentUploader;
